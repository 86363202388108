import React from 'react';
import PageLayout from '../../containers/pageLayout/PageLayout';
import UserTable from '../../containers/userTable/UserTable';
import UserModal from '../../containers/users/userModal';

const Users = () => (
  <div className="App">
    <PageLayout>
      <UserModal
        id={null}
        triggerType="add"
      />
      <UserTable />
    </PageLayout>
  </div>
);

export default Users;
