import { connect } from 'react-redux';

import { resetSearchState } from '../../actions/search';
import {
  fetchPermitsWithGroup,
  fetchPermit,
  resetPermitState,
  openPermitModal,
  closePermitModal,
} from '../../actions/permit';
import { fetchAllGroupsNoLimit } from '../../actions/group';
import { tableItemsPerPage } from '../../config';

import PermitTable from '../../components/table/PermitTable/index';

const init = (dispatch, groupId) => {
        dispatch(
          fetchPermitsWithGroup({
            groupId,
            currentPage: 0,
            itemsPerPage: tableItemsPerPage,
          }),
        );
        dispatch(resetSearchState());
      },
      mapStateToProps = (state, ownProps) => ({
        groupId: ownProps.groupId,
        permits: state.permits,
        singleGroup: state.singleGroup,
        pagination: state.pagination,
        modalIsOpen: state.permitModalIsOpen,
      }),
      mapDispatchToProps = (dispatch, ownProps) => ({
        // `init` is NOT a function passed to the component,
        // but a function called when the container loads
        // It's a hacky version of componentWillMount
        init: init(dispatch, ownProps.groupId),
        handlePaginationChange: (event, activePage) => {
          dispatch(
            fetchPermitsWithGroup({
              groupId: ownProps.groupId,
              currentPage: activePage,
              itemsPerPage: tableItemsPerPage,
            }),
          );
        },
        onModalTriggerClick: (permitId) => {
          if (permitId !== -1) {
            dispatch(
              fetchPermit({
                permitId,
                callbackAction: openPermitModal,
              }),
            );
          } else {
            dispatch(resetPermitState());
            dispatch(
              fetchAllGroupsNoLimit({
                callbackAction: openPermitModal,
              }),
            );
          }
        },
        closeModal: () => {
          dispatch(closePermitModal());
        },
        onModalClose: () => {
          dispatch(resetPermitState());
        },
      });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PermitTable);
