import { connect } from 'react-redux';
import UserModal from '../../components/modals/userModal';


import * as userActions from '../../actions/user';
import { initialState } from '../../reducers/index';

const blankUser = initialState.singleUser,
      mapStateToProps = (state, ownProps) => {
        const singleUser = (ownProps.triggerType === 'add') ? blankUser : state.singleUser;
        return {
          ...ownProps,
          singleUser,
        };
      },
      mapDispatchToProps = (dispatch, ownProps) => ({
        onOpen: (callback) => {
          if (ownProps.triggerType !== 'add') {
            dispatch(userActions.fetchUser(ownProps.id, callback));
          }
        },
        onSubmit: (formState, callback) => {
          if (ownProps.triggerType === 'add') {
            dispatch(userActions.addUser(formState, callback));
          } else {
            dispatch(userActions.editUser(formState, callback));
          }
        },
      });

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
