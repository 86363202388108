const generateStringList = (strings) => {
  if (!strings || strings.length === 0) {
    return '';
  }

  if (strings && strings.length === 1) {
    return strings[0];
  }

  if (strings && strings.length === 2) {
    return strings.join(' and ');
  }


  return [strings.slice(0, -1).join(', '), strings.slice(-1)[0]].join(' and ');
};

export default generateStringList;
