import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Input, Button /* , Checkbox */} from 'semantic-ui-react';
import Logo from '../logo/Logo';
import './_login.scss';

const LoginForm = (props) => {
        let usernameInput = null,
            passwordInput = null;
        const { action, dispatch, authError } = props,
              onSubmit = (event) => {
                event.preventDefault();
                const formState = {
                  username: usernameInput.inputRef.current.value,
                  password: passwordInput.inputRef.current.value,
                };
                action(formState)(dispatch);
              },
              setUsernameInputRef = (element) => {
                usernameInput = element;
              },
              setPasswordInputRef = (element) => {
                passwordInput = element;
              };


        return (
          <div className="interstital authentication">
            <div className="form-container">
              <Logo />
              <form
                className="login-container"
                onSubmit={(event) => {
                  onSubmit(event);
                }}
              >
                <h1 className="authentication__heading">Log In</h1>
                <Input
                  type="text"
                  id="auth-login__username"
                  name="username"
                  placeholder="Username"
                  error={authError}
                  className="login-field"
                  ref={setUsernameInputRef}

                />
                <Input
                  type="password"
                  id="auth-login__password"
                  name="password"
                  placeholder="Password"
                  error={authError}
                  className="login-field"
                  ref={setPasswordInputRef}
                />
                {authError && <div className="login-error">Please enter a valid username and password</div>}
                <Button className="cta primary">
                  Submit
                </Button>

                {/* <Checkbox
            toggle
            id="auth-login__remember-user"
            name="remember-user" */}
                {/* eslint-disable-next-line  max-len  */}
                {/* eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control */}
                {/* label={<label>Remember me?</label>}
          /> */}
              </form>
            </div>
          </div>
        );
      },

      mapStateToProps = (state) => {
        const { authError } = state;
        return { authError };
      };


LoginForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  authError: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(LoginForm);
