import { connect } from 'react-redux';
import getParameterByName from '../../helpers/getParameterByName';

import {
  fetchPermitSearch,
} from '../../actions/search';
import {
  fetchPermit,
  resetPermitState,
  openPermitModal,
  closePermitModal,
} from '../../actions/permit';
import { fetchAllGroupsNoLimit } from '../../actions/group';
import { tableItemsPerPage } from '../../config';

import SearchTable from '../../components/table/SearchTable';

const mapStateToProps = state => ({
        searchData: state.search.results,
        pagination: state.pagination,
        modalIsOpen: state.permitModalIsOpen,
        groupId: state.searchGroup.id,
        isSearching: state.search.isSearching,
      }),
      mapDispatchToProps = dispatch => ({
        handlePaginationChange: (event, activePage) => {
          dispatch(fetchPermitSearch({
            searchString: getParameterByName('s'),
            currentPage: activePage,
            itemsPerPage: tableItemsPerPage,
          }));
        },
        handlePageLoad: () => {
          dispatch(fetchPermitSearch({
            searchString: getParameterByName('s'),
            currentPage: 0,
            itemsPerPage: tableItemsPerPage,
          }));
        },
        onModalTriggerClick: (permitId) => {
          if (permitId !== -1) {
            dispatch(fetchPermit({
              permitId,
              callbackAction: openPermitModal,
            }));
          } else {
            dispatch(resetPermitState());
            dispatch(fetchAllGroupsNoLimit({
              callbackAction: openPermitModal,
            }));
          }
        },
        closeModal: () => {
          dispatch(closePermitModal());
        },
        onModalClose: (currentPage) => {
          dispatch(resetPermitState());
          dispatch(fetchPermitSearch({
            searchString: getParameterByName('s'),
            currentPage,
            itemsPerPage: tableItemsPerPage,
          }));
        },
      });

export default connect(mapStateToProps, mapDispatchToProps)(SearchTable);
