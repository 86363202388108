import React from 'react';
import PropTypes from 'prop-types';

import { Container, Dropdown } from 'semantic-ui-react';

import './style.scss';
import { selectOptions } from '../../../config';
import { flattenOptionsObject } from '../../../helpers/forms';

const StatusSelect = (props) => {
  const {
          idPrefix,
          onChange,
          className,
          initialStatus,
          setStatus,
        } = props,
        statusOptionsArray = flattenOptionsObject(
          Object.entries(selectOptions.statusOptions).map(option => ({ ...option[1] })),
        ),
        updateStateAndForm = (event, element) => {
          const { value } = element;

          setStatus(value);
          onChange(event, element);
        };

  return (
    <Container>
      <Dropdown
        id={`${idPrefix}__status`}
        name="status"
        placeholder="Status"
        className={className}
        options={statusOptionsArray}
        defaultValue={initialStatus}
        onChange={updateStateAndForm}
      />
    </Container>
  );
};

StatusSelect.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  initialStatus: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default StatusSelect;
