import { validationFormats } from '../config';

const dateFormatters = {
  formatDate: ddmmyyyy => (
    ddmmyyyy.replace(validationFormats.date, '$3-$2-$1')
  ),
  formatDateEndOfDay: ddmmyyyy => (
    `${ddmmyyyy.replace(validationFormats.date, '$3-$2-$1')} 23:59:59`
  ),
};

export default dateFormatters;
