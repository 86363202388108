import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const mapStateToProps = (state, ownProps) => ({
        ...ownProps,
        loggedIn: state.loggedIn,
      }),
      PublicOnlyRoute = (props) => {
        const { routeComponent: Component, loggedIn } = props;

        if (!loggedIn) {
          return <Route {...props} render={componentProps => <Component {...componentProps} />} />;
        }

        return <Redirect to={{ pathname: '/' }} />;
      };


PublicOnlyRoute.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  routeComponent: PropTypes.func.isRequired,
};


export default connect(mapStateToProps)(PublicOnlyRoute);
