import { tableItemsPerPage } from '../config';
import types from './action-types';
import {
  postApi,
  addApi,
  updateApi,
} from './index';
import dateFormatters from '../helpers/dateFormatters';

import ENDPOINTS from '../helpers/envConfig';

const permitsEndpoint = process.env.REACT_APP_API_URL + ENDPOINTS.paths.permits,
      requestPermitsWithGroup = () => ({
        type: types.REQUEST_PERMITS_WITH_GROUP,
      }),
      receivedPermitsWithGroup = json => ({
        type: types.RECEIVE_PERMITS_WITH_GROUP,
        results: json,
      }),
      requestPermit = () => ({
        type: types.REQUEST_PERMIT,
      }),
      receivedPermit = json => ({
        type: types.RECEIVE_PERMIT,
        results: json,
      }),
      submitPermit = json => ({
        type: types.SUBMIT_PERMIT,
        results: json,
      }),
      submittedPermit = json => ({
        type: types.SUBMITTED_PERMIT,
        results: json,
      }),
      resettingPermitState = () => ({
        type: types.PERMIT_RESET,
        results: null,
      }),
      openingPermitModal = () => ({
        type: types.OPEN_PERMIT_MODAL,
        results: null,
      }),
      closingPermitModal = () => ({
        type: types.CLOSE_PERMIT_MODAL,
        results: null,
      });

export function fetchPermitsWithGroup({ groupId, currentPage, itemsPerPage }) {
  const endpoint = permitsEndpoint + ENDPOINTS.actions.allPermitsWithGroup,
        data = {
          GroupId: groupId,
          CurrentPage: currentPage || 1,
          ItemsPerPage: itemsPerPage || tableItemsPerPage,
        },
        request = requestPermitsWithGroup,
        received = receivedPermitsWithGroup;

  return (dispatch) => {
    postApi({
      dispatch,
      endpoint,
      data,
      request,
      received,
    });
  };
}

export function fetchPermit({ permitId, callbackFunction, callbackAction }) {
  const data = { PermitId: permitId };

  return (dispatch) => {
    const endpoint = permitsEndpoint + ENDPOINTS.actions.fetch,
          request = requestPermit,
          received = receivedPermit;

    postApi({
      dispatch,
      endpoint,
      data,
      request,
      received,
      callbackFunction,
      callbackAction,
    });
  };
}

export function addPermit({
  groupId, name, start, expires, description, vrm,
  vrmDescription, currentPage, callbackFunction, callbackAction,
}) {
  const data = {
          PermitId: -1,
          PermitHolderName: name,
          PermitDescription: description,
          VRM: vrm,
          VehicleDescription: vrmDescription,
          StartDate: dateFormatters.formatDate(start),
          EndDate: dateFormatters.formatDateEndOfDay(expires),
          GroupId: groupId,
        },
        endpoint = permitsEndpoint,
        request = submitPermit,
        received = submittedPermit,
        actionParams = {
          groupId,
          currentPage,
          itemsPerPage: tableItemsPerPage,
        };

  return (dispatch) => {
    addApi({
      dispatch,
      endpoint,
      request,
      received,
      data,
      callbackAction,
      callbackFunction,
      actionParams,
    });
  };
}

export function editPermit({
  permitId, groupId, name, start, expires, description,
  vrm, vrmDescription, currentPage, callbackFunction,
}) {
  const data = {
          PermitId: permitId,
          PermitHolderName: name,
          PermitDescription: description,
          VRM: vrm,
          VehicleDescription: vrmDescription,
          StartDate: dateFormatters.formatDate(start),
          EndDate: dateFormatters.formatDateEndOfDay(expires),
          GroupId: groupId,
        },
        endpoint = permitsEndpoint,
        request = submitPermit,
        received = submittedPermit,
        callbackAction = fetchPermitsWithGroup,
        actionParams = { groupId, currentPage, itemsPerPage: tableItemsPerPage };

  return (dispatch) => {
    updateApi({
      dispatch,
      endpoint,
      request,
      received,
      data,
      callbackAction,
      callbackFunction,
      actionParams,
    });
  };
}

export function resetPermitState() {
  return (dispatch) => {
    dispatch(resettingPermitState());
  };
}

export function openPermitModal() {
  return (dispatch) => {
    dispatch(openingPermitModal());
  };
}

export function closePermitModal() {
  return (dispatch) => {
    dispatch(closingPermitModal());
  };
}
