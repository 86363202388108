import { tableItemsPerPage, clientId } from '../config';
import types from './action-types';
import { postApi, addApi, updateApi } from './index';
import ENDPOINTS from '../helpers/envConfig';

const groupsEndpoint = process.env.REACT_APP_API_URL + ENDPOINTS.paths.groups,
      requestAllGroups = () => ({
        type: types.REQUEST_ALL_GROUPS,
      }),
      receivedAllGroups = json => ({
        type: types.RECEIVE_ALL_GROUPS,
        results: json,
      }),
      requestGroup = () => ({
        type: types.REQUEST_GROUP,
      }),
      requestAllGroupsNoLimit = () => ({
        type: types.REQUEST_ALL_GROUPS_NO_LIMIT,
      }),
      receivedAllGroupsNoLimit = json => ({
        type: types.RECEIVE_ALL_GROUPS_NO_LIMIT,
        results: json,
      }),
      submitGroup = json => ({
        type: types.SUBMIT_GROUP,
        results: json,
      }),
      submittedGroup = json => ({
        type: types.SUBMITTED_GROUP,
        results: json,
      }),
      receivedGroup = json => ({
        type: types.RECEIVE_GROUP,
        results: json,
      }),
      resettingGroupState = () => ({
        type: types.GROUP_RESET,
        results: null,
      }),
      openingGroupModal = () => ({
        type: types.OPEN_GROUP_MODAL,
        results: null,
      }),
      closingGroupModal = () => ({
        type: types.CLOSE_GROUP_MODAL,
        results: null,
      });

export function fetchAllGroups({ currentPage, itemsPerPage }) {
  const endpoint = groupsEndpoint + ENDPOINTS.actions.allGroupsByClient,
        data = {
          ClientId: clientId,
          CurrentPage: currentPage || 1,
          ItemsPerPage: itemsPerPage || tableItemsPerPage,
        },
        request = requestAllGroups,
        received = receivedAllGroups;

  return (dispatch) => {
    postApi({
      dispatch,
      endpoint,
      data,
      request,
      received,
    });
  };
}

export function fetchGroup({ groupId, callbackFunction, callbackAction }) {
  const data = { GroupId: groupId },
        endpoint = groupsEndpoint + ENDPOINTS.actions.retrieve,
        request = requestGroup,
        received = receivedGroup;

  return (dispatch) => {
    postApi({
      dispatch,
      endpoint,
      data,
      request,
      received,
      callbackFunction,
      callbackAction,
    });
  };
}

export function fetchAllGroupsNoLimit({ callbackFunction, callbackAction }) {
  const endpoint = groupsEndpoint + ENDPOINTS.actions.allGroupsByClientNoLimit,
        data = { ClientId: clientId },
        request = requestAllGroupsNoLimit,
        received = receivedAllGroupsNoLimit;

  return (dispatch) => {
    postApi({
      dispatch,
      endpoint,
      data,
      request,
      received,
      callbackFunction,
      callbackAction,
    });
  };
}

export function addGroup({
  groupId, permitType, title, description,
  daysOfWeek, startTime, endTime, sites,
  currentPage, callbackFunction,
}) {
  const data = {
          GroupId: groupId,
          PermitTypeId: permitType,
          Title: title,
          Description: description,
          ClientId: clientId,
          DaysOfWeek: daysOfWeek,
          StartTime: startTime,
          EndTime: endTime,
          Sites: sites,
        },
        endpoint = groupsEndpoint,
        request = submitGroup,
        received = submittedGroup,
        callbackAction = fetchAllGroups,
        actionParams = {
          currentPage,
          tableItemsPerPage,
        };

  return (dispatch) => {
    addApi({
      dispatch,
      endpoint,
      request,
      received,
      data,
      callbackAction,
      callbackFunction,
      actionParams,
    });
  };
}

export function editGroup({
  groupId, permitType, title, description,
  daysOfWeek, startTime, endTime, sites,
  currentPage, callbackFunction,
}) {
  const data = {
          GroupId: groupId,
          PermitTypeId: permitType,
          Title: title,
          Description: description,
          ClientId: clientId,
          DaysOfWeek: daysOfWeek,
          StartTime: startTime,
          EndTime: endTime,
          Sites: sites,
        },
        endpoint = groupsEndpoint,
        request = submitGroup,
        received = submittedGroup,
        callbackAction = fetchAllGroups,
        actionParams = {
          currentPage,
          tableItemsPerPage,
        };

  return (dispatch) => {
    updateApi({
      dispatch,
      endpoint,
      request,
      received,
      data,
      callbackAction,
      callbackFunction,
      actionParams,
    });
  };
}

export function resetGroupState() {
  return (dispatch) => {
    dispatch(resettingGroupState());
  };
}

export function openGroupModal() {
  return (dispatch) => {
    dispatch(openingGroupModal());
  };
}

export function closeGroupModal() {
  return (dispatch) => {
    dispatch(closingGroupModal());
  };
}
