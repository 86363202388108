import moment from 'moment';
import { locale } from '../config';
import types from '../actions/action-types';

const singleUser = {
        id: -1,
        name: '',
        surname: '',
        type: 'customer',
        email: '',
        status: 'inactive',
        added: new Date().toLocaleDateString('en-GB'),
      },
      singleGroup = {
        groupId: -1,
        title: '',
        permitType: 1,
        description: '',
        sites: [],
        daysOfWeek: '127',
        startTime: '',
        endTime: '',
      },
      singlePermit = {
        groupId: -1,
        permitId: -1,
        status: 'inactive',
        name: '',
        start: moment().format(locale.dateFormat),
        expires: moment().format(locale.dateFormat),
        description: '',
        vrm: '',
        vrmDescription: '',
      },
      groupsNoLimit = [
        {
          id: -1,
          name: '',
        },
      ],
      initialSearchState = {
        isSearching: false,
        results: {},
        term: '',
      },
      pagination = {
        currentPage: 0,
      };

export const initialState = {
  loading: false,
  users: [],
  groups: [],
  permits: [],
  loggedIn: false,
  authError: false,
  singleGroup,
  singleUser,
  singlePermit,
  groupsNoLimit,
  groupModalIsOpen: false,
  permitModalIsOpen: false,
  search: initialSearchState,
  searchGroup: {},
  pagination,
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_AUTHENTICATION:
      return { ...state, loading: true, authError: false };

    case types.RECEIVE_AUTHENTICATION: {
      return {
        ...state,
        loggedIn: action.results.status === 'true',
        authError: action.results.status === 'false',
        loading: false,
        // token: authenticatedToken,
      };
    }

    case types.REQUEST_ALL_USERS:
      return {
        ...state,
        users: [],
        loading: true,
      };

    case types.RECEIVE_ALL_USERS: {
      return {
        ...state,
        loading: false,
        users: action.results.map(user => ({
          ...user,
        })),
      };
    }

    case types.REQUEST_USER:
      return { ...state, singleUser, loading: true };

    case types.SUBMIT_USER:
      return { ...state, loading: true };

    case types.RECEIVE_USER: {
      const resultsType = typeof action.results;
      let actionRow;
      if (resultsType === 'undefined') {
        return { ...state };
      }

      if (Array.isArray(action.results)) {
        actionRow = { ...action.results[0] };
      } else {
        actionRow = { ...action.results };
      }

      return {
        ...state,
        singleUser: { ...actionRow },
        loading: false,
      };
    }

    case types.REQUEST_ALL_GROUPS:
      return {
        ...state,
        groups: [],
        loading: true,
      };

    case types.RECEIVE_ALL_GROUPS: {
      return {
        ...state,
        loading: false,
        groups: action.results.data.map(group => ({
          ...group,
        })),
        pagination: action.results.pagination,
      };
    }

    case types.REQUEST_ALL_GROUPS_NO_LIMIT:
      return {
        ...state,
        groupsNoLimit: [],
        loading: true,
      };

    case types.RECEIVE_ALL_GROUPS_NO_LIMIT: {
      return {
        ...state,
        loading: false,
        groupsNoLimit: action.results,
      };
    }

    case types.OPEN_GROUP_MODAL: {
      return {
        ...state,
        groupModalIsOpen: true,
      };
    }

    case types.CLOSE_GROUP_MODAL: {
      return {
        ...state,
        singleGroup: initialState.singleGroup,
        groupModalIsOpen: false,
      };
    }

    case types.REQUEST_PERMIT:
      return {
        ...state,
        singlePermit,
        loading: true,
      };

    case types.SUBMIT_PERMIT:
      return {
        ...state,
        loading: true,
      };

    case types.SUBMITTED_PERMIT:
      return {
        ...state,
        loading: false,
      };

    case types.RECEIVE_PERMIT: {
      const resultsType = typeof action.results;

      if (resultsType === 'undefined') {
        return { ...state };
      }

      const {
              endDate,
              group,
              groupId,
              id,
              permitDescription,
              permitHolderName,
              permitId,
              startDate,
              vehicleDescription,
              vrm,
            } = action.results,
            modifiedPermit = {
              expires: moment.parseZone(endDate, moment.ISO_8601).format(locale.dateFormat),
              group,
              groupId,
              id,
              name: permitHolderName,
              description: permitDescription,
              permitId,
              start: moment.parseZone(startDate, moment.ISO_8601).format(locale.dateFormat),
              vrm,
              vrmDescription: vehicleDescription,
            };

      return {
        ...state,
        singlePermit: modifiedPermit,
        searchGroup: group,
        loading: false,
      };
    }

    case types.REQUEST_PERMITS_WITH_GROUP:
      return {
        ...state,
        singleGroup: [],
        permits: [],
        loading: true,
      };

    case types.RECEIVE_PERMITS_WITH_GROUP: {
      const { pagination } = action.results,
            singleGroup = [action.results.data.group],
            permits = action.results.data.permits.map(permit => ({
              ...permit,
            }));

      return {
        ...state,
        loading: false,
        singleGroup,
        permits,
        pagination,
      };
    }

    case types.PERMIT_RESET:
      return { ...state, singlePermit };


    case types.OPEN_PERMIT_MODAL: {
      return {
        ...state,
        permitModalIsOpen: true,
      };
    }

    case types.CLOSE_PERMIT_MODAL: {
      return {
        ...state,
        permitModalIsOpen: false,
      };
    }

    case types.REQUEST_GROUP:
      return { ...state, singleGroup, loading: true };

    case types.RECEIVE_GROUP: {
      const resultsType = typeof action.results;

      if (resultsType === 'undefined') {
        return { ...state };
      }

      const today = moment().format(locale.dateFormat),
            datetimeFormat = `${locale.dateFormat} ${locale.secondsTimeFormat}`,
            {
              groupId,
              title,
              description,
              clientId,
              daysOfWeek,
              startTime,
              endTime,
              sites,
              permitType,
            } = action.results,

            modifiedGroup = {
              groupId,
              title,
              description,
              clientId,
              daysOfWeek,
              startTime: moment(`${today} ${startTime}`, datetimeFormat).format(locale.minutesTimeFormat),
              endTime: moment(`${today} ${endTime}`, datetimeFormat).format(locale.minutesTimeFormat),
              sites: sites.filter(s => s.enabled === 'true')
                .map(s => s.siteId),
              permitType: permitType.id,
            };
      return {
        ...state,
        singleGroup: { ...modifiedGroup },
        loading: false,
      };
    }

    case types.SUBMIT_GROUP:
      return {
        ...state,
        loading: true,
      };

    case types.SUBMITTED_GROUP:
      return {
        ...state,
        loading: false,
      };

    case types.GROUP_RESET:
      return { ...state, singleGroup };


    case types.REQUEST_PERMIT_SEARCH:

      return {
        ...state,
        loading: false,
        search: {
          isSearching: true,
          term: state.search.term,
        },
      };

    case types.RECEIVE_PERMIT_SEARCH: {
      const { pagination } = action.results,
            searchResults = action.results.data;
      return {
        ...state,
        loading: false,
        pagination,
        search: {
          term: state.search.term,
          isSearching: false,
          results: {
            searchResults,
          },
        },
      };
    }

    case types.SEARCH_START: {
      const { searchTerm } = action;
      return {
        ...state,
        loading: false,
        search: {
          isSearching: true,
          term: searchTerm,
        },
      };
    }

    case types.SEARCH_RESET: {
      return {
        ...state,
        search: initialSearchState,
      };
    }

    default:
      return state;
  }
};

export default reducer;
