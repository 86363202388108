/* eslint-disable react/destructuring-assignment, react/prop-types */

import React from 'react';
import Pagination from '../pagination/Pagination';

const Table = props => (
  <div>
    <table className={`table basic very ui${props.className ? ` ${props.className}` : ''}`}>
      {props.headerType && <props.headerType />}
      <tbody>
        {props.tableData && props.tableData.length > 0 && props.tableData.map((row) => {
          const additionalData = {};

          if (typeof props.addToRowData !== 'undefined') {
            Object.keys(props.addToRowData).forEach((key) => {
              additionalData[key] = props.addToRowData[key];
            });
          }
          return (
            <props.rowType
              key={row[props.keyField]}
              rowData={{ ...row, ...additionalData }}
              openModal={props.openModal}
              editable={props.editable}
            />
          );
        })}
      </tbody>
    </table>
    {
      props.pagination
      && (
        <Pagination
          handlePaginationChange={props.handlePaginationChange}
          pagination={props.pagination}
        />
      )
    }
  </div>
);

Table.defaultProps = {
  openModal: () => {},
};

export default Table;
