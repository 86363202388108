import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LeftArrowIcon } from '../svgIcons/SVGIcons';

import './styles.scss';

const BackButton = (props) => {
  const { href, children } = props;

  return (
    <div className="back-button">
      <Link to={`${href}`}>
        <LeftArrowIcon />
        <span className="button-text">{children}</span>
      </Link>
    </div>
  );
};

BackButton.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default BackButton;
