import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from 'semantic-ui-react';

const SiteButton = ({
  id,
  label,
  name,
  onChange,
  isEditable,
  isActive,
}) => (
  isEditable
    ? (
      <Checkbox
        label={label}
        id={id}
        name={name}
        className="checkbutton"
        onChange={onChange}
      />
    )
    : (
      <div className={`checkbutton checkbutton-${isActive ? 'active' : 'inactive'} checkbutton--disabled`}>
        {label}
      </div>
    )
);

export default SiteButton;

SiteButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
};
