import React from 'react';
import { tableColumns } from '../../../../config';

const UserHeader = () => (
  <thead>
    <tr>
      {tableColumns.users.map((column, i) => (<th key={i}>{column}</th>))}
    </tr>
  </thead>
);

export default UserHeader;
