import { endpoints } from '../config';
import types from './action-types';
import { fetchApi, addApi, updateApi } from './index';

const ENDPOINTS = endpoints.LOCAL,
      usersEndpoint = process.env.REACT_APP_API_URL + ENDPOINTS.paths.users,
      requestAllUsers = () => ({
        type: types.REQUEST_ALL_USERS,
      }),
      receivedAllUsers = json => ({
        type: types.RECEIVE_ALL_USERS,
        results: json,
      }),
      // requestUser = () => ({
      //   type: types.REQUEST_USER,
      // }),
      receivedUser = json => ({
        type: types.RECEIVE_USER,
        results: json,
      }),
      submitUser = json => ({
        type: types.SUBMIT_USER,
        results: json,
      });

export function fetchAllUsers() {
  const endpoint = usersEndpoint,
        request = requestAllUsers,
        received = receivedAllUsers;

  return (dispatch) => {
    fetchApi({
      dispatch,
      endpoint,
      request,
      received,
    });
  };
}

export function fetchUser(id, callback) {
  const endpoint = `${usersEndpoint}?id=${id}`,
        request = requestAllUsers,
        received = receivedAllUsers;

  return (dispatch) => {
    fetchApi({
      dispatch,
      endpoint,
      request,
      received,
      callback,
    });
  };
}

export function addUser(data, callbackFunction) {
  const { id } = data,
        endpoint = `${usersEndpoint}/${id}`,
        request = submitUser,
        received = receivedUser,
        callbackAction = fetchAllUsers;

  return (dispatch) => {
    addApi({
      dispatch,
      endpoint,
      request,
      received,
      data,
      callbackAction,
      callbackFunction,
    });
  };
}

export function editUser(data, callback) {
  const { id } = data,
        route = `/${id}`;

  return (dispatch) => {
    updateApi(
      dispatch, usersEndpoint + route, submitUser, receivedUser, data, fetchAllUsers, callback,
    );
  };
}
