import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as SUPagination } from 'semantic-ui-react';

import './styles.scss';

const Pagination = ({ pagination, handlePaginationChange }) => (
  (pagination.pageCount >= pagination.currentPage) && (
    <SUPagination
      activePage={pagination.currentPage}
      boundaryRange={0}
      ellipsisItem={null}
      firstItem={null}
      lastItem={null}
      siblingRange={1}
      onPageChange={(event, { activePage }) => handlePaginationChange(event, activePage)}
      totalPages={pagination.pageCount}
    />
  )
);

export default Pagination;

Pagination.propTypes = {
  handlePaginationChange: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
  }).isRequired,
};
