import React from 'react';
import PageLayout from '../../containers/pageLayout/PageLayout';
import GroupTable from '../../containers/groupTable/GroupTable';


const Groups = () => (
  <div className="App">
    <PageLayout>
      <GroupTable />
    </PageLayout>
  </div>
);

export default Groups;
