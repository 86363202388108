import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = (props) => {
        const { routeComponent: Component, loggedIn } = props;

        if (loggedIn) {
          return <Route {...props} render={componentProps => <Component {...componentProps} />} />;
        }

        return <Redirect to={{ pathname: '/login' }} />;
      },
      mapStateToProps = (state, ownProps) => ({
        loggedIn: state.loggedIn,
        ...ownProps,
      });


PrivateRoute.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  routeComponent: PropTypes.elementType,
};

PrivateRoute.defaultProps = {
  routeComponent: () => {},
};

export default connect(mapStateToProps)(PrivateRoute);
