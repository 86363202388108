import React from 'react';

import './styles.scss';

const Avatar = (props) => {
  const {
    content, name, surname, group,
  } = props;

  if (!content && !name && !surname) return null;

  return (
    <span className={`avatar avatar-${group ? 'group' : 'user'}`}>
      {content && content.toUpperCase()}
      {name && name.charAt(0).toUpperCase()}
      {surname && surname.charAt(0).toUpperCase()}
    </span>
  );
};

export default Avatar;
