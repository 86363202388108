import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
// import { Confirm } from 'semantic-ui-react';

import Avatar from '../../../avatar/Avatar';
import ModalTrigger from '../../../modals/modalTrigger';
import { EditIcon } from '../../../svgIcons/SVGIcons';
import generateStringList from '../../../../helpers/strings';

import './styles.scss';

const GroupRow = (props) => {
  // let confirmDeleteDialog = false;

  const { rowData, editable, openModal } = props,
        {
          groupId,
          title,
          abbreviation,
          permitCount,
          permitType,
          times,
          sites,
        } = rowData;

  // confirmDelete = () => { confirmDeleteDialog = true; },
  // cancelDelete = () => { confirmDeleteDialog = false; },
  // deleteRow = () => {};

  return (
    <tr>
      <td className="shrunk-to-fit no-bottom-border table__cell--avatar">
        <Avatar content={abbreviation} className="secondary-avatar" group />
      </td>
      <td className="larger-font align-middle table__cell--details">
        <Link to={`/groups/${groupId}`}>
          <div className="group-name">{title}</div>
          <div className="group-description">{[permitType, times].join(' - ')}</div>
          <div className="group-sites">{generateStringList(sites.split('   '))}</div>
        </Link>
      </td>
      <td align="right">
        <Link className="group-permit-count" to={`/groups/${groupId}`}>
          <span className="group-permit-count-number">{permitCount}</span>
          {' '}
          Permit
          {permitCount === 1 ? '' : 's'}
        </Link>
      </td>
      {editable
      && (
        <td className="table-tools">
          <ModalTrigger
            modalid={groupId}
            icon={EditIcon}
            className="button--edit"
            onClick={openModal}
          >
            <EditIcon />
          </ModalTrigger>
          {/* <Confirm
            open={confirmDeleteDialog}
            onCanel={cancelDelete}
            onCancel={cancelDelete}
            onConfirm={deleteRow}
          /> */}
        </td>
      )
      }

    </tr>
  );
};

GroupRow.propTypes = {
  rowData: PropTypes.shape({
    groupId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    abbreviation: PropTypes.string,
    permitCount: PropTypes.number.isRequired,
  }).isRequired,
  editable: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
};

GroupRow.defaultProps = {
  editable: true,
};

export default GroupRow;
