import { connect } from 'react-redux';

import * as permitActions from '../../actions/permit';

import PermitModal from '../../components/modals/permitModal';

const mapStateToProps = (state, ownProps) => {
        const { currentPage } = state.pagination,
              { groupsNoLimit, singlePermit, loading } = state;

        return {
          ...ownProps,
          singlePermit,
          groupsNoLimit,
          loading,
          currentPage,
          addPermitCallbackAction: (typeof ownProps.addPermitCallbackAction !== 'undefined') ? ownProps.addPermitCallbackAction : permitActions.fetchPermitsWithGroup,
        };
      },
      mapDispatchToProps = dispatch => ({
        onSubmit: (formState, currentPage, callbackFunction, callbackAction) => {
          if (formState.permitId === -1) {
            dispatch(permitActions.addPermit({
              ...formState,
              currentPage,
              callbackFunction,
              callbackAction,
            }));
          } else {
            dispatch(permitActions.editPermit({
              ...formState,
              currentPage,
              callbackFunction,
            }));
          }
        },
      });

export default connect(mapStateToProps, mapDispatchToProps)(PermitModal);
