import React from 'react';

import PageLayout from '../../containers/pageLayout/PageLayout';
import SearchTable from '../../containers/SearchTable/SearchTable';

const Search = () => (
  <div className="App">
    <PageLayout>
      <SearchTable />
    </PageLayout>
  </div>
);


export default Search;
