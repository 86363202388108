import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../logo/Logo';
import {
  PermitIcon,
  // UsersIcon,
} from '../svgIcons/SVGIcons';
import Logout from '../logout';

import './styles.scss';

const Navigation = () => (
  <div>
    <nav className="Navigation">
      <div className="navigation__logo">
        <NavLink exact to="/groups" activeClassName="active">
          <Logo />
        </NavLink>
      </div>
      <ul>
        <li>
          <NavLink to="/groups" activeClassName="active">
            <div className="navigation__icon">
              <PermitIcon />
            </div>
            <span className="double-line">
            Group
              <br />
            Permits
            </span>
          </NavLink>
        </li>
        {/* <li>
        <NavLink exact to="/users" activeClassName="active">
          <div className="navigation__icon">
            <UsersIcon />
          </div>
          <span>Users</span>
        </NavLink>
      </li> */}
      </ul>
      <Logout />
    </nav>
  </div>
);

export default Navigation;
