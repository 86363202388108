import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'semantic-ui-react';

const ModalTrigger = (props) => {
  const {
    modalid,
    onClick,
    className,
    label,
  } = props;

  return (
    <Button
      modalid={modalid}
      className={`button--icon ${className}`}
      onClick={onClick}
    >
      <props.icon />
      <span>{label}</span>
    </Button>
  );
};

ModalTrigger.propTypes = {
  modalid: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string,
};

ModalTrigger.defaultProps = {
  label: '',
};

export default ModalTrigger;
