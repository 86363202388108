/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

import { Input, Form } from 'semantic-ui-react';
import { SearchIcon } from '../svgIcons/SVGIcons';
import { searchStart } from '../../actions/search';

import './styles.scss';

function Search({ dispatch, term }) {
  const onSubmit = (event) => {
    if (event && event.target[0].value) {
      searchStart({ searchTerm: event.target[0].value })(dispatch);
    }
  };

  return (
    <div className="search-container">
      <Form onSubmit={onSubmit}>
        <Form.Field className="search-field">
          <SearchIcon />
          <Input
            key={term}
            placeholder="Search..."
            defaultValue={term}
          />
        </Form.Field>
      </Form>
    </div>
  );
}

export default connect(state => ({
  ...state.search,
}))(Search);
