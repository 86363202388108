import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';

import rootReducer from '../reducers/index';

const persistConfig = {
        key: 'root',
        storage,
        blacklist: ['authError'],
      },
      persistedReducer = persistReducer(persistConfig, rootReducer),
      middlewares = (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') ? [thunk] : [thunk, logger];

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares),
);
export const persistor = persistStore(store);
