import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Dropdown, Form, Modal, Input, Button, Select,
} from 'semantic-ui-react';

import { sites as availableSites, daysOfWeekOptions, selectOptions } from '../../config';
import { flattenOptionsObject } from '../../helpers/forms';

import SiteButton from '../siteButton';
import { buildValidationState, validateAllFields } from '../../services/validation';
import ValidationStatus from '../validationStatus';

import { browserClass } from '../../browser-detect';

const GroupModal = (props) => {
  let { modalHasInitialised } = props;

  const {
          modalid,
          onSubmit,
          singleGroup,
          modalIsOpen,
          closeGroupModal,
          onModalClose,
          loading,
          currentPage,
        } = props,
        formState = { ...singleGroup },
        formType = 'groups',
        setters = {},
        idPrefix = 'edit-group',
        permitOptions = selectOptions.permitTypeOptions,
        // Local input states
        [titleHookeed, setTitle] = useState(formState.title),
        [permitTypeHooked, setPermitType] = useState(formState.permitType),
        [descriptionHooked, setDescription] = useState(formState.description),
        [daysOfWeekHooked, setDaysOfWeek] = useState(formState.daysOfWeek),
        [sitesHooked, setSites] = useState(formState.sites),
        [startTimeHooked, setStartTime] = useState(formState.startTime),
        [endTimeHooked, setEndTime] = useState(formState.endTime),
        [validationState, setValidationState] = useState(buildValidationState(formType)),
        setFormState = (name, value) => {
          formState[name] = value;
        },
        closeModal = useCallback(() => {
          closeGroupModal();
          onModalClose();
          modalHasInitialised = false;
        }, [closeGroupModal, onModalClose]),
        handleSubmit = () => {
          setValidationState({
            ...validateAllFields(formType, formState, validationState),
            submitPending: true,
          });
        },
        updateStateAndHooks = (name, value) => {
          setFormState(name, value);

          if (typeof setters[name] !== 'undefined') {
            setters[name](value);
          }
        },
        onChange = (event, element) => {
          const { name, value } = element;

          updateStateAndHooks(name, value);
        },
        onBlur = (...params) => {
          let target = null;

          if (params[0].target && params[0].target.value !== undefined) {
            // eslint-disable-next-line prefer-destructuring
            target = params[0].target;
          } else {
            // eslint-disable-next-line prefer-destructuring
            target = params[1];
          }
          const { name, value } = target;

          updateStateAndHooks(name, value);
        },
        processSites = (event) => {
          let sites = sitesHooked;

          if (event.target.checked) {
            if (!sites.includes(event.target.name)) {
              sites.push(event.target.name);
            }
          } else {
            sites = sites.filter(site => site !== event.target.name);
          }

          updateStateAndHooks('sites', sites);
        };

  if (!modalHasInitialised) {
    setFormState('title', titleHookeed);
    setFormState('permitType', permitTypeHooked);
    setFormState('description', descriptionHooked);
    setFormState('daysOfWeek', daysOfWeekHooked);
    setFormState('sites', sitesHooked);
    setFormState('startTime', startTimeHooked);
    setFormState('endTime', endTimeHooked);

    setters.title = setTitle;
    setters.permitType = setPermitType;
    setters.description = setDescription;
    setters.daysOfWeek = setDaysOfWeek;
    setters.sites = setSites;
    setters.startTime = setStartTime;
    setters.endTime = setEndTime;

    modalHasInitialised = true;
  }


  useEffect(() => {
    const { submitPending, formIsValid } = validationState;

    if (submitPending && formIsValid) {
      onSubmit(formState, currentPage, closeModal);
      setValidationState({ ...validationState, submitPending: false });
    }
  }, [onSubmit, formState, currentPage, closeModal, setValidationState, validationState]);

  return (
    <Modal
      groupid={modalid}
      id={`${idPrefix}-${modalid}`}
      className={`${idPrefix} ${browserClass}`}
      open={modalIsOpen}
      closeIcon={(
        <Button
          icon="close"
          onClick={closeModal}
          className="button--close button--icon"
        />
      )}
    >
      <Modal.Content>
        <Form
          id={`${idPrefix}__form`}
          onSubmit={handleSubmit}
        >
          <Form.Field
            className="--large"
          >
            <Input
              id={`${idPrefix}__title`}
              name="title"
              placeholder="Name of Group"
              defaultValue={titleHookeed}
              onChange={onChange}
              onBlur={onBlur}
              error={!validationState.title.fieldIsValid}
            />
            <ValidationStatus
              name="title"
              formType={formType}
              fieldValidationState={validationState.title}
            />
          </Form.Field>
          <Form.Field
            id={`${idPrefix}__permitType-field`}
            className={`--quarter-width --position-absolute ${idPrefix}__field-status`}
          >
            <Select
              id={`${idPrefix}__permitType`}
              name="permitType"
              className="--permitType --no-border-bottom"
              placeholder="Type"
              options={flattenOptionsObject(permitOptions)}
              value={permitTypeHooked}
              onChange={onChange}
              onBlur={onBlur}
              error={!validationState.permitType.fieldIsValid}
              disabled={formState.groupId !== -1}
            />
            <ValidationStatus
              name="permitType"
              formType={formType}
              fieldValidationState={validationState.permitType}
            />
          </Form.Field>
          <Form.Field>
            { /* eslint-disable jsx-a11y/label-has-associated-control */ }
            <label htmlFor={`${idPrefix}__description`}>Group Description</label>
            <label className="label-description">Write a little description about the group</label>
            { /* eslint-enable jsx-a11y/label-has-associated-control */ }
            <Input
              id={`${idPrefix}__description`}
              name="description"
              defaultValue={descriptionHooked}
              onChange={onChange}
              onBlur={onBlur}
              error={!validationState.description.fieldIsValid}
            />
            <ValidationStatus
              name="description"
              formType={formType}
              fieldValidationState={validationState.description}
            />
          </Form.Field>
          <Form.Field>
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
            <label htmlFor={`${idPrefix}__daysOfWeek`}>Permissions</label>
            <Dropdown
              id={`${idPrefix}__daysOfWeek`}
              name="daysOfWeek"
              options={daysOfWeekOptions}
              value={daysOfWeekHooked && daysOfWeekHooked.toString()}
              onChange={onChange}
              onBlur={onBlur}
              selection
              label="Permissions"
              error={!validationState.daysOfWeek.fieldIsValid}
              disabled={formState.groupId !== -1}
            />
            <ValidationStatus
              name="daysOfWeek"
              formType={formType}
              fieldValidationState={validationState.daysOfWeek}
            />
          </Form.Field>
          <Form.Field>
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
            <label htmlFor={`${idPrefix}`}>Car Park</label>
            <fieldset id={`${idPrefix}__site`}>
              {
                availableSites.map(site => (
                  <SiteButton
                    id={`${idPrefix}__site_${site.siteId}`}
                    key={`${idPrefix}__site_${site.siteId}`}
                    name={`${site.siteId}`}
                    onChange={processSites}
                    onBlur={processSites}
                    label={site.displayName}
                    isEditable={formState.groupId === -1}
                    isActive={sitesHooked !== null && sitesHooked.includes(site.siteId)}
                  />
                ))}

              <ValidationStatus
                name="site"
                formType={formType}
                fieldValidationState={validationState.sites}
              />
            </fieldset>
          </Form.Field>
          <Form.Field className="--half-width">
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
            <label htmlFor={`${idPrefix}__startTime`}>Start</label>
            <Input
              id={`${idPrefix}__startTime`}
              name="startTime"
              className="masked"
              defaultValue={startTimeHooked}
              placeholder="HH:MM"
              onChange={onChange}
              onBlur={onBlur}
              disabled={formState.groupId !== -1}
              error={!validationState.startTime.fieldIsValid}
            />
            <ValidationStatus
              name="startTime"
              formType={formType}
              fieldValidationState={validationState.startTime}
            />
          </Form.Field>
          <Form.Field className="--half-width">
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
            <label htmlFor={`${idPrefix}__endTime`}>Expires</label>
            <Input
              id={`${idPrefix}__endTime`}
              name="endTime"
              className="masked"
              defaultValue={endTimeHooked}
              placeholder="HH:MM"
              onChange={onChange}
              onBlur={onBlur}
              disabled={formState.groupId !== -1}
              error={!validationState.endTime.fieldIsValid}
            />
            <ValidationStatus
              name="endTime"
              formType={formType}
              fieldValidationState={validationState.endTime}
            />
          </Form.Field>
          <Form.Field>
            <Button
              id={`${idPrefix}__save`}
              className={`${idPrefix}__save cta primary`}
              disabled={loading}
            >
              Save
            </Button>
          </Form.Field>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

GroupModal.propTypes = {
  modalHasInitialised: PropTypes.bool,
  modalid: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  singleGroup: PropTypes.shape({
    groupId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  modalIsOpen: PropTypes.bool.isRequired,
  closeGroupModal: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,

};

GroupModal.defaultProps = {
  modalHasInitialised: false,
  modalid: -1,
  singleGroup: {
    id: -1,
  },
};

export default GroupModal;
