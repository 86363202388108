/* eslint-disable react/prop-types, react/destructuring-assignment */

import React, { useEffect, Fragment } from 'react';
import Table from '../index';
import PermitHeader from '../PermitTable/PermitHeader';
import PermitRow from '../PermitTable/PermitRow';
import GroupRow from '../GroupTable/GroupRow';
import PermitModal from '../../../containers/permitModal';
import getParameterByName from '../../../helpers/getParameterByName';
import { LoadingSpinner } from '../../svgIcons/SVGIcons';

import './styles.scss';

const SearchTable = (props) => {
  const {
          groupId,
          singleGroup,
          handlePaginationChange,
          modalIsOpen,
          onModalTriggerClick,
          onModalClose,
          closeModal,
          searchData,
          pagination,
          handlePageLoad,
          isSearching,
        } = props,
        openModal = (event, element) => {
          const { modalid } = element;
          onModalTriggerClick(modalid);
        };
  useEffect(() => {
    const searchTerm = getParameterByName('s');
    if (searchTerm) {
      handlePageLoad();
    }
  }, [handlePageLoad]);

  if (isSearching) {
    return <div className="loading-spinner"><LoadingSpinner /></div>;
  }

  return (
    <div className="search-results__table">
      {searchData && searchData.searchResults.length
        ? (
          <Fragment>
            <div className="search-results__heading">
              <h4>Permits</h4>
            </div>
            <Table
              rowType={GroupRow}
              tableData={singleGroup}
              keyField="groupId"
              editable={false}
            />
            <Table
              className="permit-table"
              headerType={PermitHeader}
              addToRowData={{ groupId }}
              rowType={PermitRow}
              tableData={searchData && searchData.searchResults}
              keyField="permitId"
              pagination={pagination}
              handlePaginationChange={handlePaginationChange}
              openModal={openModal}
            />
            {
              modalIsOpen && (
                <PermitModal
                  permitId={-1}
                  groupId={groupId}
                  modalIsOpen={modalIsOpen}
                  closePermitModal={closeModal}
                  onModalClose={onModalClose}
                  currentPage={pagination ? pagination.currentPage : ''}
                />
              )
            }
          </Fragment>
        )
        : 'No results found'
      }

    </div>
  );
};

export default SearchTable;
