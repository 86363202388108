import moment from 'moment';

const paths = {},
      endpoints = {
        LOCAL: {
          paths: {
            authentication: '/security',
            users: '/users',
            groups: '/group',
            permits: '/permit',
            allpermitswithgroup: '/permit/allpermitswithgroup',
          },
          actions: {
            authenticateUser: '/authenticateUser',
            allGroupsByClient: '/AllGroupsByClient',
            allGroupsByClientNoLimit: '/AllDDGroupsByClient',
            allPermitsWithGroup: '/allpermitswithgroup',
            fetch: '',
            save: '/Save',
            retrieve: '/retrieve',
            search: '/search',
          },
        },
        DEV: {
          paths: {
            authentication: '/security',
            groups: '/group',
            permits: '/permit',
            allpermitswithgroup: '/permit/allpermitswithgroup',
          },
          actions: {
            authenticateUser: '/authenticateUser',
            allGroupsByClient: '/AllGroupsByClient',
            allGroupsByClientNoLimit: '/AllDDGroupsByClient',
            allPermitsWithGroup: '/allpermitswithgroup',
            fetch: '/retrieve',
            save: '/Save',
            retrieve: '/retrieve',
            search: '/search',
          },
        },
        PRODUCTION: {
          paths: {
            authentication: '/security',
            groups: '/group',
            permits: '/permit',
            allpermitswithgroup: '/permit/allpermitswithgroup',
          },
          actions: {
            authenticateUser: '/authenticateUser',
            allGroupsByClient: '/AllGroupsByClient',
            allGroupsByClientNoLimit: '/AllDDGroupsByClient',
            allPermitsWithGroup: '/allpermitswithgroup',
            fetch: '/retrieve',
            save: '/Save',
            retrieve: '/retrieve',
            search: '/search',
          },
        },
      },
      locale = {
        dateFormat: 'DD/MM/YYYY',
        timestampFormat: 'YYYY-MM-DD HH:mm:ss:SSS',
        secondsTimeFormat: 'HH:mm:ss',
        minutesTimeFormat: 'HH:mm',
      },
      tableColumns = {
        users: ['', 'name', 'type', 'email', 'status', 'added', ''],
        permits: ['name', 'vrm', 'status', 'start', 'expires', ''],
      },
      tableItemsPerPage = 50,
      clientId = '126',
      selectOptions = {
        statusOptions: {
          active: {
            key: 'active',
            value: 'active',
            text: 'Active',
            label: { color: 'green', empty: true, circular: true },
          },
          inactive: {
            key: 'inactive',
            value: 'inactive',
            text: 'Inactive',
            label: { color: 'red', empty: true, circular: true },
          },
        },
        userTypeOptions: {
          customer: {
            key: 'customer',
            value: 'customer',
            text: 'Customer',
          },
          editor: {
            key: 'editor',
            value: 'editor',
            text: 'Editor',
          },
          siteadmin: {
            key: 'siteadmin',
            value: 'siteadmin',
            text: 'Site Admin',
          },
        },
        permitTypeOptions: {
          STAFF: {
            key: 1,
            value: 1,
            text: 'Staff',
          },
          VISITOR: {
            key: 2,
            value: 2,
            text: 'Visitor',
          },
          PIBB: {
            key: 3,
            value: 3,
            text: 'PIBB',
          },
        },
        group: {
          title: '',
          description: '',
          daysOfWeek: '',
          sites: [],
          start: '',
          expires: '',
        },
      },
      validationFormats = {
        // eslint-disable-next-line no-useless-escape
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\s\w]).{16,}$/,
        // eslint-disable-next-line no-useless-escape
        date: /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/,
        time: /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/,
      },
      validationConfig = {
        users: {
          // status: {
          //   required: true,
          // },
          name: {
            required: true,
          },
          surname: {
            required: true,
          },
          type: {
            required: true,
            enum: selectOptions.userTypeOptions,
          },
          added: {
            required: true,
            format: validationFormats.date,
          },
          email: {
            required: false,
            format: validationFormats.email,
          },
          password: {
            format: validationFormats.password,
          },
          currentPassword: {
            dependsOnAll: ['password'],
            format: validationFormats.password,
          },
          newPassword: {
            dependsOnAll: ['password'],
            format: validationFormats.password,
          },
          repeatNewPassword: {
            dependsOnAll: ['password'],
            format: validationFormats.password,
          },
        },
        groups: {
          title: {
            required: true,
          },
          permitType: {
            required: true,
            enum: selectOptions.permitTypeOptions,
          },
          description: {
            required: true,
            maxLength: 255,
          },
          daysOfWeek: {
            required: true,
          },
          sites: {
            required: true,
          },
          startTime: {
            required: true,
            format: validationFormats.time,
          },
          endTime: {
            required: true,
            format: validationFormats.time,
          },
        },
        permits: {
          // status: {
          //   required: true,
          // },
          name: {
            required: true,
          },
          permitId: {
            required: true,
          },
          groupId: {
            required: true,
            min: 1,
          },
          start: {
            required: true,
            format: validationFormats.date,
            conditional: {
              conditionalField: 'permitId',
              conditionalOperator: '===',
              comparisonValue: -1,
              conditionalValidation: {
                minDate: moment().subtract(3, 'days').format(locale.dateFormat),
              },
            },
          },
          expires: {
            required: true,
            format: validationFormats.date,
            minDateField: 'start',
          },
          description: {
            required: false,
          },
          vrm: {
            required: true,
          },
          vrmDescription: {
            required: false,
          },
        },
      },
      errorMessages = {
        customValidation: {
          permits: {
            groupId: {
              min: 'You must select a group for this permit',
            },
            start: {
              minDate: 'Permits can not be back dated more than 3 days',
            },
            expires: {
              minDateField: 'Expiry date must be later than start date',
            },
          },
        },
        validation: {
          required: 'This field is required',
          format: 'Invalid format',
          maxLength: 'This field is too long',
          minDate: 'The date can not be this early',
        },
        users: {
          invalidUserType: 'ERR: Invalid user type',
        },
      },
      daysOfTheWeek = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
      daysOfWeekOptions = [
        {
          key: 1, text: 'All Week', value: '127', selected: true,
        },
        {
          key: 2, text: 'Monday to Friday', value: '31',
        },
        { key: 3, text: 'Weekends', value: '96' },
      ],
      sites = [
        {
          siteId: 1229,
          displayName: 'South',
        },
        {
          siteId: 1230,
          displayName: 'Market Square',
        },
        {
          siteId: 1231,
          displayName: 'Tesco',
        },
        {
          siteId: 1232,
          displayName: 'East',
        },
        {
          siteId: 1233,
          displayName: 'North A',
        },
        {
          siteId: 1234,
          displayName: 'North B',
        },
        {
          siteId: 1235,
          displayName: 'North C',
        },
      ];

export {
  paths,
  endpoints,
  locale,
  tableColumns,
  tableItemsPerPage,
  selectOptions,
  validationFormats,
  validationConfig,
  errorMessages,
  daysOfTheWeek,
  daysOfWeekOptions,
  sites,
  clientId,
};
