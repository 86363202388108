export default {
  REQUEST_AUTHENTICATION: 'REQUEST_AUTHENTICATION',
  RECEIVE_AUTHENTICATION: 'RECEIVE_AUTHENTICATION',
  REQUEST_ALL_USERS: 'REQUEST_ALL_USERS',
  RECEIVE_ALL_USERS: 'RECEIVE_ALL_USERS',
  REQUEST_USER: 'REQUEST_USER',
  RECEIVE_USER: 'RECEIVE_USER',
  SUBMIT_USER: 'SUBMIT_USER',
  REQUEST_ALL_GROUPS: 'REQUEST_ALL_GROUPS',
  RECEIVE_ALL_GROUPS: 'RECEIVED_ALL_GROUPS',
  REQUEST_ALL_GROUPS_NO_LIMIT: 'REQUEST_ALL_GROUPS_NO_LIMIT',
  RECEIVE_ALL_GROUPS_NO_LIMIT: 'RECEIVED_ALL_GROUPS_NO_LIMIT',
  REQUEST_GROUP: 'REQUEST_GROUP',
  SUBMIT_GROUP: 'SUBMIT_GROUP',
  SUBMITTED_GROUP: 'SUBMITTED_GROUP',
  RECEIVE_GROUP: 'RECEIVE_GROUP',
  GROUP_RESET: 'GROUP_RESET',
  OPEN_GROUP_MODAL: 'OPEN_GROUP_MODAL',
  CLOSE_GROUP_MODAL: 'CLOSE_GROUP_MODAL',
  REQUEST_PERMITS_WITH_GROUP: 'REQUEST_PERMITS_WITH_GROUP',
  RECEIVE_PERMITS_WITH_GROUP: 'RECEIVE_PERMITS_WITH_GROUP',
  REQUEST_PERMIT: 'REQUEST_PERMIT',
  RECEIVE_PERMIT: 'RECEIVE_PERMIT',
  SUBMIT_PERMIT: 'SUBMIT_PERMIT',
  SUBMITTED_PERMIT: 'SUBMITTED_PERMIT',
  PERMIT_RESET: 'PERMIT_RESET',
  OPEN_PERMIT_MODAL: 'OPEN_PERMIT_MODAL',
  CLOSE_PERMIT_MODAL: 'CLOSE_PERMIT_MODAL',
  REQUEST_PERMIT_SEARCH: 'REQUEST_PERMIT_SEARCH',
  RECEIVE_PERMIT_SEARCH: 'RECEIVE_PERMIT_SEARCH',
  SEARCH_START: 'SEARCH_START',
  SEARCH_RESET: 'SEARCH_RESET',
};
