import React from 'react';

import './styles.scss';

const HomeIcon = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="SVGIcon"
          width="31"
          height="22"
          viewBox="0 0 31 22"
        >
          <path
            fill="#FFF"
            fillRule="evenodd"
            d="M29.143 6.595a4.65 4.65 0 0 0-1.564-3.571l-1.454 2.519 2.482.665c.23.061.415.203.536.387zm-.193 1.232a.928.928 0 0 1-.824.175l-3.588-.961a.929.929 0 0 1-.495-1.464.926.926 0 0 1 .117-.345l1.811-3.137a4.644 4.644 0 0 0-3.792 8.426 4.643 4.643 0 0 0 6.771-2.694zM20 16h9a1 1 0 1 1 0 2h-9a1 1 0 1 1 0-2zm0 4h9a1 1 0 0 1 0 2h-9a1 1 0 1 1 0-2zM27.75.87a6.5 6.5 0 1 1-6.5 11.26A6.5 6.5 0 0 1 27.75.87zM13 2a1 1 0 0 1 1 1v18a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1zM9 6a1 1 0 0 1 1 1v14a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1zm-4 6a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0v-8a1 1 0 0 1 1-1zm-4-2a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V11a1 1 0 0 1 1-1z"
    	  />
        </svg>
      ),

      PermitIcon = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="SVGIcon"
          width="19"
          height="20"
          viewBox="0 0 26 20"
        >
          <path
            fill="#FFF"
            fillRule="evenodd"
            d="M9 17h16a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2zm-7-1a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm7-7h16a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2zM2 8a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm7-7h16a1 1 0 0 1 0 2H9a1 1 0 1 1 0-2zM2 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
          />
        </svg>
      ),

      SitesIcon = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="SVGIcon"
          width="29"
          height="20"
          viewBox="0 0 29 20"
        >
          <path
            fill="#FFF"
            fillRule="evenodd"
            d="M6 16.5V18h9.5H6v1a1 1 0 0 1-1 1H1.5A1.5 1.5 0 0 1 0 18.5V1.093C0 .474.656 0 1.46 0h8.33c.732 0 1.585.447 1.93 1.03L12.884 3H23.5A1.5 1.5 0 0 1 25 4.5V7h2c.182 0 .353.049.5.134A1 1 0 0 1 29 8v5a1 1 0 0 1-2 0V9H6v7.5zM4 17V7h19V5h-9.5c-1.503-.011-2-1-2-1L10 2H2v15a1 1 0 1 0 2 0z"
          />
        </svg>
      ),

      UsersIcon = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="SVGIcon"
          width="12"
          height="19"
          viewBox="0 0 17 19"
        >
          <path
            fill="#FFF"
            fillRule="evenodd"
            d="M9.339 11.877a.874.874 0 0 1 .041.267v4.41a.875.875 0 0 1-.875.874h-.302a.875.875 0 0 1-.875-.875v-4.41c0-.09.014-.179.04-.262-2.832.449-4.989 2.768-4.989 5.561 0 .176.009.35.025.52h-.222A1.027 1.027 0 1 1 .14 17.81a7.328 7.328 0 0 1-.01-.368c0-3.05 1.895-5.684 4.64-6.929A5.648 5.648 0 0 1 2.718 6.15C2.717 3.028 5.24.497 8.354.497c3.114 0 5.638 2.53 5.638 5.653a5.648 5.648 0 0 1-2.063 4.372c2.736 1.247 4.621 3.878 4.621 6.92 0 .124-.003.246-.009.368a1.027 1.027 0 0 1-2.043.152h-.166c.017-.17.025-.344.025-.52 0-2.803-2.172-5.128-5.018-5.565zM8.354 2.55a3.592 3.592 0 0 0-3.585 3.6c0 1.988 1.605 3.6 3.585 3.6a3.592 3.592 0 0 0 3.585-3.6c0-1.988-1.605-3.6-3.585-3.6z"
          />
        </svg>
      ),

      PCNsIcon = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="SVGIcon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="#FFF"
            fillRule="evenodd"
            d="M2 5.975h20a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-14a2 2 0 0 1 2-2zm-1-5h22a1 1 0 1 1 0 2H1a1 1 0 0 1 0-2zm1 7v14h20v-14H2zm3.5 2a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
          />
        </svg>
      ),

      PrintIcon = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="29"
          viewBox="0 0 29 29"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#2C0826"
              d="M14.025 1.19c7.446 0 13.482 6.035 13.482 13.478 0 7.444-6.036 13.477-13.482 13.477-7.445 0-13.48-6.033-13.48-13.477C.544 7.225 6.58 1.19 14.024 1.19z"
            />
            <g fill="#000">
              <path d="M18.436 14.861a.288.288 0 0 1-.283-.293V8.955c0-.203-.184-.368-.41-.368H12.4a.288.288 0 0 1-.283-.294c0-.162.127-.293.283-.293h5.342c.539 0 .977.429.977.955v5.613a.288.288 0 0 1-.283.293M9.766 14.861a.288.288 0 0 1-.282-.293v-3.542c0-.162.126-.293.282-.293.156 0 .283.13.283.293v3.542a.288.288 0 0 1-.283.293" />
              <path d="M10.449 10.733h1.257c.227 0 .411-.166.411-.37V9.002l-1.668 1.732zm1.257.586h-1.94a.283.283 0 0 1-.26-.18.301.301 0 0 1 .06-.32L12.2 8.086a.275.275 0 0 1 .308-.064.294.294 0 0 1 .174.271v2.07a.967.967 0 0 1-.976.956zM16.683 13.2H11.79a.288.288 0 0 1-.282-.293c0-.162.126-.293.282-.293h4.892c.156 0 .282.131.282.293a.288.288 0 0 1-.282.293M16.683 14.548H11.79a.288.288 0 0 1-.282-.293c0-.162.126-.293.282-.293h4.892c.156 0 .282.131.282.293a.288.288 0 0 1-.282.293M8.596 16.038a.032.032 0 0 0-.03.033v4.235c0 .018.013.033.03.033h11.808a.032.032 0 0 0 .03-.033v-4.235a.032.032 0 0 0-.03-.033H8.596zm11.808 4.887H8.596A.609.609 0 0 1 8 20.306v-4.235c0-.342.268-.62.596-.62h11.808c.328 0 .596.278.596.62v4.235c0 .342-.268.62-.596.62z" />
              <path d="M19.302 17.949a.644.644 0 0 1-.631.655.644.644 0 0 1-.632-.655c0-.362.283-.655.632-.655.348 0 .631.293.631.655M17.036 17.949a.644.644 0 0 1-.632.655.644.644 0 0 1-.631-.655c0-.362.282-.655.631-.655.349 0 .632.293.632.655" />
            </g>
          </g>
        </svg>
      ),

      EditIcon = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#2C0826"
              d="M14.025 1.19c7.446 0 13.482 6.035 13.482 13.478 0 7.444-6.036 13.477-13.482 13.477-7.445 0-13.48-6.033-13.48-13.477C.544 7.225 6.58 1.19 14.024 1.19z"
            />
            <g fill="#000">
              <path d="M8.973 15.613c-.074.086-.146.185-.232.267a.815.815 0 0 0-.223.42c-.135.56-.282 1.119-.424 1.678l-.265 1.036c.03-.002.043 0 .055-.004l2.541-.81a.223.223 0 0 0 .096-.053c.127-.137.25-.278.365-.407l-1.913-2.127m6.947-7.68L9.504 15.07l1.885 2.07 6.396-7.131-1.865-2.074M7.366 19.946c-.279 0-.421-.24-.346-.538.14-.554.285-1.107.428-1.661.163-.632.321-1.265.492-1.894a.85.85 0 0 1 .176-.333c2.494-2.78 4.992-5.557 7.49-8.334.222-.247.397-.248.618-.001L18.5 9.723c.19.211.189.406 0 .616-2.507 2.788-5.013 5.576-7.523 8.36a.771.771 0 0 1-.323.208c-1.042.34-2.087.669-3.132 1-.06.02-.124.031-.155.039M15.438 19.203H19.6c.271 0 .438.236.357.498-.047.148-.146.23-.283.248-.072.01-.146.006-.218.006h-8.134a.792.792 0 0 1-.203-.019c-.157-.041-.262-.212-.249-.385.015-.186.144-.332.31-.347.045-.004.09-.001.136-.001h4.122" />
            </g>
          </g>
        </svg>
      ),

      AddIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" className="add-icon">
          <g fill="none" fillRule="evenodd">
            <path fill="#2C0826" d="M10.148.625c5.524 0 10.002 4.477 10.002 10 0 5.522-4.478 9.999-10.002 9.999-5.524 0-10.002-4.477-10.002-10C.146 5.103 4.624.626 10.148.626z" />
            <path fill="#FFF" d="M10.781 14.312c0 .311-.252.563-.562.563h-.563a.563.563 0 0 1-.562-.563v-2.53H6.562A.563.563 0 0 1 6 11.218v-.563c0-.31.252-.562.562-.562h2.532V7.562c0-.31.252-.562.562-.562h.563c.31 0 .562.252.562.562v2.532h2.531c.311 0 .563.252.563.562v.563c0 .31-.252.562-.563.562h-2.53v2.531z" />
          </g>
        </svg>
      ),

      SearchIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path fill="#000" fillRule="evenodd" d="M6.513 13.077l-5.03 4.928L0 16.555l4.973-4.873a7.048 7.048 0 0 1-1.63-4.51C3.344 3.211 6.624 0 10.672 0 14.719 0 18 3.21 18 7.172c0 3.96-3.28 7.172-7.328 7.172a7.406 7.406 0 0 1-4.16-1.267zm4.174-11.015c-2.899 0-5.25 2.295-5.25 5.125s2.351 5.126 5.25 5.126c2.9 0 5.25-2.295 5.25-5.126 0-2.83-2.35-5.125-5.25-5.125z" />
        </svg>

      ),

      LeftArrowIcon = () => (
        <svg width="19px" height="16px" viewBox="0 0 19 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Site_active" transform="translate(-161.000000, -40.000000)" fill="#000000">
              <path d="M164.215911,48.95496 L169.586588,54.37284 C169.954714,54.74386 169.954714,55.34568 169.586588,55.7171 C169.218462,56.08812 168.621932,56.08812 168.25407,55.7171 L161.590688,48.99504 C161.561117,48.9652461 161.533924,48.9339558 161.509107,48.9014097 C161.211186,48.7859989 161.000098,48.496662 161.000098,48.15782 L161.000098,47.84188 C161.000098,47.4990922 161.216505,47.2068261 161.520337,47.0944139 C161.542064,47.0669774 161.565515,47.0404766 161.590688,47.01506 L168.25407,40.28606 C168.621932,39.91388 169.218462,39.91388 169.586588,40.28606 C169.954714,40.65748 169.954714,41.26006 169.586588,41.63186 L164.225706,47.04512 L179.203228,47.04512 C179.643294,47.04512 180.000112,47.40188 180.000112,47.84188 L180.000112,48.15782 C180.000112,48.5982 179.643294,48.95496 179.203228,48.95496 L164.215911,48.95496 Z" id="arrow" />
            </g>
          </g>
        </svg>
      ),
      LoadingSpinner = () => (
        <svg id="loading-spinner" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
          <g fill="#CB9942" fillRule="evenodd">
            <path d="M17.5 9c-.825 0-1.5-.675-1.5-1.5v-6c0-.825.675-1.5 1.5-1.5S19 .675 19 1.5v6c0 .825-.675 1.5-1.5 1.5" opacity=".65" />
            <path d="M21.75 10.139a1.504 1.504 0 0 1-.549-2.05l3-5.195a1.504 1.504 0 0 1 2.049-.55c.715.413.962 1.335.549 2.05l-3 5.196a1.504 1.504 0 0 1-2.049.549" opacity=".75" />
            <path d="M24.861 13.25a1.504 1.504 0 0 1 .55-2.049l5.195-3a1.504 1.504 0 0 1 2.05.549 1.504 1.504 0 0 1-.55 2.049l-5.196 3a1.504 1.504 0 0 1-2.049-.549" opacity=".85" />
            <path d="M26 17.5c0-.825.675-1.5 1.5-1.5h6c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5h-6c-.825 0-1.5-.675-1.5-1.5" opacity=".9" />
            <path d="M24.861 21.75a1.504 1.504 0 0 1 2.05-.549l5.195 3c.715.413.962 1.334.55 2.049a1.504 1.504 0 0 1-2.05.549l-5.196-3a1.504 1.504 0 0 1-.549-2.049" opacity=".2" />
            <path d="M21.75 24.861a1.504 1.504 0 0 1 2.049.55l3 5.195a1.504 1.504 0 0 1-.549 2.05 1.504 1.504 0 0 1-2.049-.55l-3-5.196a1.504 1.504 0 0 1 .549-2.049" opacity=".25" />
            <path d="M17.5 26c.825 0 1.5.675 1.5 1.5v6c0 .825-.675 1.5-1.5 1.5s-1.5-.675-1.5-1.5v-6c0-.825.675-1.5 1.5-1.5" opacity=".3" />
            <path d="M13.25 24.861c.715.413.962 1.335.549 2.05l-3 5.195a1.504 1.504 0 0 1-2.049.55 1.504 1.504 0 0 1-.549-2.05l3-5.196a1.504 1.504 0 0 1 2.049-.549" opacity=".35" />
            <path d="M10.139 21.75a1.504 1.504 0 0 1-.55 2.049l-5.195 3a1.504 1.504 0 0 1-2.05-.549 1.504 1.504 0 0 1 .55-2.049l5.196-3a1.504 1.504 0 0 1 2.049.549" opacity=".4" />
            <path d="M9 17.5c0 .825-.675 1.5-1.5 1.5h-6C.675 19 0 18.325 0 17.5S.675 16 1.5 16h6c.825 0 1.5.675 1.5 1.5" opacity=".45" />
            <path d="M10.139 13.25a1.504 1.504 0 0 1-2.05.549l-5.195-3a1.504 1.504 0 0 1-.55-2.049 1.504 1.504 0 0 1 2.05-.549l5.196 3c.714.412.961 1.334.549 2.049" opacity=".5" />
            <path d="M13.25 10.139a1.504 1.504 0 0 1-2.049-.55l-3-5.195a1.504 1.504 0 0 1 .549-2.05 1.504 1.504 0 0 1 2.049.55l3 5.196a1.504 1.504 0 0 1-.549 2.049" opacity=".55" />
          </g>
        </svg>
      );

export {
  HomeIcon,
  PermitIcon,
  SitesIcon,
  UsersIcon,
  PCNsIcon,
  PrintIcon,
  EditIcon,
  AddIcon,
  SearchIcon,
  LeftArrowIcon,
  LoadingSpinner,
};
