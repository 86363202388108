import React from 'react';
import './_logo.scss';
import logo from './images/logo.svg';
import logoText from './images/logo-text.svg';
import logoBeta from './images/logo-beta.svg';

const Logo = () => (
  <div className="nexus-logo__">
    <img src={logo} alt="GroupNexus logo" className="nexus-logo__image" />
    <img src={logoText} alt="GroupNexus text" className="nexus-logo__text" />
    <img src={logoBeta} alt="GroupNexus Beta" className="nexus-logo__beta" />
  </div>
);

export default Logo;
