import React from 'react';
import PropTypes from 'prop-types';

import './_authentication.scss';
import Login from '../../components/login/Login';

const Authentication = (props) => {
  const { action } = props;

  return (
    <Login
      action={action}

    />
  );
};


Authentication.propTypes = {
  action: PropTypes.func.isRequired,
};


export default Authentication;
