/* eslint-disable import/prefer-default-export */

import { tableItemsPerPage, clientId } from '../config';
import types from './action-types';
import { postApi } from './index';
import ENDPOINTS from '../helpers/envConfig';

const searchEndpoint = process.env.REACT_APP_API_URL + ENDPOINTS.paths.permits,
      requestPermitSearch = () => ({
        type: types.REQUEST_PERMIT_SEARCH,
      }),
      receivePermitSearch = json => ({
        type: types.RECEIVE_PERMIT_SEARCH,
        results: json,
      }),
      receiveSearchStart = searchTerm => ({
        type: types.SEARCH_START,
        searchTerm,
      }),
      resettingSearchState = () => ({
        type: types.SEARCH_RESET,
      });

export function fetchPermitSearch({
  searchString, currentPage, itemsPerPage, callback,
}) {
  const endpoint = searchEndpoint + ENDPOINTS.actions.search,
        data = {
          ClientId: clientId,
          CurrentPage: currentPage || 1,
          ItemsPerPage: itemsPerPage || tableItemsPerPage,
          SearchString: searchString,
        },
        request = requestPermitSearch,
        received = receivePermitSearch;

  return (dispatch) => {
    postApi({
      dispatch,
      endpoint,
      data,
      request,
      received,
      callback,
    });
  };
}

export function searchStart({ searchTerm }) {
  window.location.href = `/search/?s=${searchTerm}`;
  return (dispatch) => {
    dispatch(receiveSearchStart(searchTerm));
  };
}

export function resetSearchState() {
  return (dispatch) => {
    dispatch(resettingSearchState());
  };
}
