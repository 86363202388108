import { connect } from 'react-redux';

import { resetSearchState } from '../../actions/search';
import {
  fetchAllGroups,
  fetchGroup,
  resetGroupState,
  openGroupModal,
  closeGroupModal,
  fetchAllGroupsNoLimit,
} from '../../actions/group';
import {
  resetPermitState,
  openPermitModal,
  closePermitModal,
} from '../../actions/permit';
import { tableItemsPerPage } from '../../config';

import GroupTable from '../../components/table/GroupTable/index';

const init = (dispatch) => {
        dispatch(fetchAllGroups({
          currentPage: 0,
          itemsPerPage: tableItemsPerPage,
        }));
        dispatch(resetSearchState());
      },
      mapStateToProps = state => ({
        groups: state.groups,
        pagination: state.pagination,
        groupModalIsOpen: state.groupModalIsOpen,
        permitModalIsOpen: state.permitModalIsOpen,
        addPermitCallbackAction: fetchAllGroups,
      }),
      mapDispatchToProps = dispatch => ({
        // `init` is NOT a function passed to the component,
        // but a function called when the container loads
        // It's a hacky version of componentWillMount
        init: init(dispatch),
        handlePaginationChange: (event, activePage) => {
          dispatch(fetchAllGroups({
            currentPage: activePage,
            itemsPerPage: tableItemsPerPage,
          }));
        },
        onGroupModalTriggerClick: (groupId) => {
          if (groupId !== -1) {
            dispatch(fetchGroup({
              groupId,
              callbackAction: openGroupModal,
            }));
          } else {
            dispatch(resetGroupState());
            dispatch(openGroupModal());
          }
        },
        closeGroupModal: () => {
          dispatch(closeGroupModal());
        },
        onGroupModalClose: () => {
          dispatch(resetGroupState());
        },
        onPermitModalTriggerClick: () => {
          dispatch(resetPermitState());
          dispatch(fetchAllGroupsNoLimit({
            callbackAction: openPermitModal,
          }));
        },
        closePermitModal: () => {
          dispatch(closePermitModal());
        },
        onPermitModalClose: () => {
          dispatch(resetPermitState());
        },
      });

export default connect(mapStateToProps, mapDispatchToProps)(GroupTable);
