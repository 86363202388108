export default function getParameterByName(name, url) {
  const setUrl = url || window.location.href,
        replacedName = name.replace(/[[\]]/g, '\\$&'),
        regex = new RegExp(`[?&]${replacedName}(=([^&#]*)|&|#|$)`),
        results = regex.exec(setUrl);

  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
