import React from 'react';
import PropTypes from 'prop-types';

import Table from '../index';
import GroupRow from './GroupRow';
import GroupModal from '../../../containers/groupModal';
import PermitModal from '../../../containers/permitModal';
import ModalTrigger from '../../modals/modalTrigger';
import { AddIcon } from '../../svgIcons/SVGIcons';

const GroupTable = (props) => {
  const {
          groups,
          pagination,
          handlePaginationChange,
          groupModalIsOpen,
          permitModalIsOpen,
          onGroupModalTriggerClick,
          onGroupModalClose,
          closeGroupModal,
          onPermitModalTriggerClick,
          closePermitModal,
          onPermitModalClose,
          addPermitCallbackAction,
        } = props,
        openGroupModal = (event, element) => {
          const { modalid } = element;

          onGroupModalTriggerClick(modalid);
        },
        openPermitModal = (event, element) => {
          const { modalid } = element;

          onPermitModalTriggerClick(modalid);
        };

  return (
    <div>
      <ModalTrigger
        modalid={-1}
        icon={AddIcon}
        className="button--add tabletools--modal-trigger"
        onClick={openGroupModal}
        label="Add New Group"
      >
        <AddIcon />
      </ModalTrigger>
      <ModalTrigger
        modalid={-1}
        icon={AddIcon}
        className="button--add tabletools--modal-trigger"
        onClick={openPermitModal}
        label="Add New Permit"
      >
        <AddIcon />
      </ModalTrigger>
      <Table
        className="group-table --full-width"
        rowType={GroupRow}
        tableData={groups}
        keyField="groupId"
        pagination={pagination}
        handlePaginationChange={handlePaginationChange}
        openModal={openGroupModal}
      />
      {
        groupModalIsOpen && (
          <GroupModal
            groupId={-1}
            modalIsOpen={groupModalIsOpen}
            closeGroupModal={closeGroupModal}
            onModalClose={onGroupModalClose}
          />
        )
      }
      {
        permitModalIsOpen && (
          <PermitModal
            groupId={-1}
            modalIsOpen={permitModalIsOpen}
            closePermitModal={closePermitModal}
            onModalClose={onPermitModalClose}
            addPermitCallbackAction={addPermitCallbackAction}
            showGroupSelect
          />
        )
      }
    </div>
  );
};

GroupTable.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
  }).isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  groupModalIsOpen: PropTypes.bool.isRequired,
  permitModalIsOpen: PropTypes.bool.isRequired,
  onGroupModalTriggerClick: PropTypes.func.isRequired,
  onGroupModalClose: PropTypes.func.isRequired,
  closeGroupModal: PropTypes.func.isRequired,
  onPermitModalTriggerClick: PropTypes.func.isRequired,
  onPermitModalClose: PropTypes.func.isRequired,
  closePermitModal: PropTypes.func.isRequired,
  addPermitCallbackAction: PropTypes.func.isRequired,
};

export default GroupTable;
