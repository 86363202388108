import { connect } from 'react-redux';
import GroupModal from '../../components/modals/groupModal';
import { initialState } from '../../reducers/index';

import * as groupActions from '../../actions/group';

const blankGroup = { ...initialState.singleGroup },
      mapStateToProps = (state, ownProps) => {
        const singleGroup = (ownProps.triggerType === 'add') ? blankGroup : { ...state.singleGroup },
              { currentPage } = state.pagination,
              { loading } = state;

        return {
          ...ownProps,
          singleGroup,
          loading,
          currentPage,
        };
      },
      mapDispatchToProps = dispatch => ({
        onSubmit: (formState, currentPage, callbackFunction) => {
          if (formState.groupId === -1) {
            dispatch(groupActions.addGroup({
              ...formState,
              currentPage,
              callbackFunction,
            }));
          } else {
            dispatch(groupActions.editGroup({
              ...formState,
              currentPage,
              callbackFunction,
            }));
          }
        },
      });

export default connect(mapStateToProps, mapDispatchToProps)(GroupModal);
