/* globals fetch */
import types from './action-types';
import ENDPOINTS from '../helpers/envConfig';

const usersEndpoint = process.env.REACT_APP_API_URL
        + ENDPOINTS.paths.authentication
        + ENDPOINTS.actions.authenticateUser,
      requestAuthentication = () => ({
        type: types.REQUEST_AUTHENTICATION,
      }),
      receivedAuthentication = json => ({
        type: types.RECEIVE_AUTHENTICATION,
        results: json,
      });

async function createUpdateApi(params) {
  const {
          dispatch,
          endpoint,
          request,
          received,
          data,
          method,
          callbackAction,
          callbackFunction,
          actionParams,
        } = params,
        modifiedActionParams = (typeof actionParams !== 'undefined') ? actionParams : {};

  dispatch(request());
  try {
    const response = await fetch(endpoint, {
            method,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: process.env.REACT_APP_API_KEY,
            },
            body: JSON.stringify(data),
          }),
          json = await response.json();

    dispatch(received(json));
    if (typeof callbackAction !== 'undefined') {
      callbackAction(modifiedActionParams)(dispatch);
    }

    if (typeof callbackFunction !== 'undefined') {
      callbackFunction();
    }
  } catch (error) {
    /* eslint-disable-next-line no-console */
    const json = console.log('An error occurred.', error);
    dispatch(received(json));
  }
}

export async function fetchApi(params) {
  const {
    dispatch,
    endpoint,
    request,
    received,
    callback,
  } = params;

  dispatch(request());
  try {
    const response = await fetch(endpoint),
          json = await response.json();
    dispatch(received(json));
    if (typeof callback !== 'undefined') {
      callback(json);
    }
  } catch (error) {
    /* eslint-disable-next-line no-console */
    const json = console.log('An error occurred.', error);
    dispatch(received(json));
  }
}

export async function postApi(params) {
  const {
    dispatch,
    endpoint,
    data,
    request,
    received,
    callbackFunction,
    callbackAction,
    actionParams,
  } = params;

  dispatch(request());
  try {
    const response = await fetch(`${endpoint}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: process.env.REACT_APP_API_KEY,
            },
            body: JSON.stringify(data),
          }),
          json = await response.json();
    dispatch(received(json));
    if (typeof callbackFunction !== 'undefined') {
      callbackFunction(json);
    }
    if (typeof callbackAction !== 'undefined') {
      dispatch(callbackAction(actionParams));
    }
  } catch (error) {
    /* eslint-disable-next-line no-console */
    const json = console.log('An error occurred.', error);
    dispatch(received(json));
  }
}

export async function addApi({
  dispatch, endpoint, request, received, data,
  callbackFunction, callbackAction, actionParams,
}) {
  const modifiedEndpoint = endpoint + ENDPOINTS.actions.save,
        method = 'POST';

  createUpdateApi({
    dispatch,
    endpoint: modifiedEndpoint,
    request,
    received,
    data,
    method,
    callbackAction,
    callbackFunction,
    actionParams,
  });
}

export async function updateApi({
  dispatch, endpoint, request, received, data,
  callbackAction, callbackFunction, actionParams,
}) {
  const modifiedEndpoint = endpoint + ENDPOINTS.actions.save,
        method = 'POST';

  createUpdateApi({
    dispatch,
    endpoint: modifiedEndpoint,
    request,
    received,
    data,
    method,
    callbackAction,
    callbackFunction,
    actionParams,
  });
}

export function fetchAuthentication({ username, password }) {
  const data = {
          UserName: username,
          Password: password,
        },
        endpoint = usersEndpoint,
        request = requestAuthentication,
        received = receivedAuthentication;

  return (dispatch) => {
    postApi({
      dispatch,
      endpoint,
      data,
      request,
      received,
    });
  };
}

export function logoutUser() {
  localStorage.removeItem('persist:root');
  window.location.href = '/';
}
