/* eslint-disable react/prop-types, semi, react/destructuring-assignment */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import UserHeader from '../../components/table/UserTable/UserHeader';
import UserRow from '../../components/table/UserTable/UserRow';
import Table from '../../components/table';
import * as userActions from '../../actions/user';

class UserTable extends React.Component {
  componentWillMount() {
    this.props.actions.fetchAllUsers();
  }

  render() {
    const { users } = this.props;
    return (
      <Table
        className="user-table --full-width"
        headerType={UserHeader}
        rowType={UserRow}
        keyField="id"
        tableData={users}
      />
    );
  }
}

export default connect(
  state => ({
    users: state.users,
  }),
  dispatch => ({
    actions: bindActionCreators(userActions, dispatch),
  }),
)(UserTable);
