import React from 'react';
import PropTypes from 'prop-types';

import BackButton from '../../backButton';
import Table from '../index';
import PermitHeader from './PermitHeader';
import PermitRow from './PermitRow';
import GroupRow from '../GroupTable/GroupRow';
import PermitModal from '../../../containers/permitModal';
import ModalTrigger from '../../modals/modalTrigger';
import { AddIcon } from '../../svgIcons/SVGIcons';

import './styles.scss';

const PermitTable = (props) => {
  const {
          groupId,
          singleGroup,
          permits,
          pagination,
          handlePaginationChange,
          modalIsOpen,
          onModalTriggerClick,
          onModalClose,
          closeModal,
        } = props,
        openModal = (event, element) => {
          const { modalid } = element;

          onModalTriggerClick(modalid);
        };

  return (
    <div>
      <ModalTrigger
        modalid={-1}
        icon={AddIcon}
        className="button--add tabletools--modal-trigger"
        onClick={openModal}
        modalHasInitialisd={false}
        label="Add New Permit"
      >
        <AddIcon />
      </ModalTrigger>
      <BackButton href="/groups">
        <span className="bold">All</span>
        {' '}
        groups
      </BackButton>
      <Table
        className="permit-table__group-header --full-width"
        rowType={GroupRow}
        tableData={singleGroup}
        keyField="groupId"
        editable={false}
      />
      <Table
        className="permit-table  --full-width"
        headerType={PermitHeader}
        addToRowData={{ groupId }}
        rowType={PermitRow}
        tableData={permits}
        keyField="permitId"
        pagination={pagination}
        handlePaginationChange={handlePaginationChange}
        openModal={openModal}
      />
      {
        modalIsOpen && (
          <PermitModal
            permitId={-1}
            groupId={groupId}
            modalIsOpen={modalIsOpen}
            closePermitModal={closeModal}
            onModalClose={onModalClose}
          />
        )
      }
    </div>
  );
};

PermitTable.propTypes = {
  singleGroup: PropTypes.arrayOf(PropTypes.object).isRequired,
  permits: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupId: PropTypes.number.isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
  }).isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  onModalTriggerClick: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default PermitTable;
