import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutUser } from '../../actions/index';

import './styles.scss';

const Logout = () => (
  <button
    type="button"
    className="logout-button"
    onClick={logoutUser}
  >
    LOG OUT
  </button>
);

export default connect(
  dispatch => ({
    logoutUser: bindActionCreators(logoutUser, dispatch),
  }),
)(Logout);
