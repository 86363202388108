import React from 'react';
import PropTypes from 'prop-types';

import { Button, Confirm } from 'semantic-ui-react';

import { selectOptions, errorMessages } from '../../../../config';
import Avatar from '../../../avatar/Avatar';
import UserModal from '../../../../containers/users/userModal';

import './styles.scss';

const UserRow = (props) => {
  let confirmDeleteDialog = false;

  const { rowData } = props,
        {
          id,
          name,
          surname,
          type,
          email,
          status,
          added,
        } = rowData,
        confirmDelete = () => { confirmDeleteDialog = true; },
        cancelDelete = () => { confirmDeleteDialog = false; },
        deleteUser = () => {};

  return (
    <tr>
      <td className="shrunk-to-fit no-bottom-border">
        <Avatar name={name} surname={surname} />
      </td>
      <td className="larger-font">
        {name}
        {' '}
        {surname}
      </td>
      <td>
        {
          selectOptions.userTypeOptions[type]
            ? selectOptions.userTypeOptions[type].text
            : errorMessages.users.invalidUserType
        }
      </td>
      <td>
        <a className="user-email" href={`mailto:${email}`}>
          {email}
        </a>
      </td>
      <td className={`user-status status-${status}`}>{selectOptions.statusOptions[status].text}</td>
      <td>{added}</td>
      <td className="table-tools">
        <UserModal
          id={id}
          triggerType="edit"
        />
        <Button
          id={id}
          icon="trash"
          onClick={confirmDelete}
          className="button--delete button--icon --border-thin"
        />
        <Confirm
          open={confirmDeleteDialog}
          onCancel={cancelDelete}
          onConfirm={deleteUser}
        />
      </td>
    </tr>
  );
};

export default UserRow;

UserRow.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string,
    type: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    added: PropTypes.string.isRequired,
  }).isRequired,
};
