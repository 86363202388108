import React from 'react';
import { tableColumns } from '../../../../config';

const PermitHeader = () => (
  <thead>
    <tr>
      {tableColumns.permits.map((column, i) => (<th key={i}>{column}</th>))}
    </tr>
  </thead>
);

export default PermitHeader;
