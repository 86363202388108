import React from 'react';

const NotFound = () => (
  <div className="App">
    <header className="App-header">
      <h1
        style={{
          textAlign: 'center',
        }}
      >
        404 Not Found!
      </h1>
    </header>
  </div>
);

export default NotFound;
