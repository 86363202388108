import React from 'react';
import PropTypes from 'prop-types';

import { errorMessages } from '../../config';

const ValidationStatus = ({ fieldValidationState, name, formType }) => (
  <ul
    id={`${name}__validation`}
    className="field__validation-messages"
  >
    {Object.keys(fieldValidationState).map((error) => {
      if (error !== 'fieldIsValid' && fieldValidationState[error] === false) {
        if (
          name
          && formType
          && errorMessages.customValidation[formType]
          && errorMessages.customValidation[formType][name]
          && errorMessages.customValidation[formType][name][error]
        ) {
          return <li key={error}>{errorMessages.customValidation[formType][name][error]}</li>;
        }
        return <li key={error}>{errorMessages.validation[error]}</li>;
      }
      return '';
    })}
  </ul>
);

ValidationStatus.propTypes = {
  fieldValidationState: PropTypes.objectOf(PropTypes.bool).isRequired,
  name: PropTypes.string,
  formType: PropTypes.string,
};

ValidationStatus.defaultProps = {
  name: null,
  formType: null,
};

export default ValidationStatus;
