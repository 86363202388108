import React, { useState } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line object-curly-newline
import { Form, Modal, Select, Input, Button } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

import './styles.scss';
import { selectOptions } from '../../config';
import { flattenOptionsObject } from '../../helpers/forms';
import StatusSelect from '../inputs/statusSelect/StatusSelect';
import { buildValidationState, validateAllFields } from '../../services/validation';
import ValidationStatus from '../validationStatus';

import { browserClass } from '../../browser-detect'

const UserModal = (props) => {
  let buttonIcon,
      addEditButtonClass,
      showPasswordFields;

  const {
          modalid,
          onOpen,
          onSubmit,
          singleUser,
          triggerType,
        } = props,
        formType = 'users',
        formState = singleUser,
        setters = {},
        idPrefix = 'edit-user',
        dateClearable = false,
        // Local state
        [modalIsOpen, setModalIsOpen] = useState(false),
        // Local input states
        [statusHooked, setStatus] = useState(singleUser.status),
        [addedHooked, setAddedDate] = useState(singleUser.added),
        [typeHooked, setType] = useState(singleUser.type),
        [validationState, setValidationState] = useState(buildValidationState(formType)),
        setFormState = (name, value) => {
          formState[name] = value;
        },
        openModal = () => {
          setModalIsOpen(true);
        },
        reinitialiseHooks = (data) => {
          Object.entries(setters).forEach((entry) => {
            const field = entry[0],
                  setField = entry[1];

            setField(data[0][field]);
          });
        },
        closeModal = () => {
          setModalIsOpen(false);
        },
        handleSubmit = () => {
          setValidationState(validateAllFields(formType, formState, validationState));

          if (formState.formIsValid === true) {
            onSubmit(formState, closeModal);
          }
        },
        onChange = (event, element) => {
          const { name, value } = element;

          setFormState(name, value);

          if (typeof setters[name] !== 'undefined') {
            setters[name](value);
          }
        },
        onBlur = ({ target }) => {
          setFormState(target.name, target.value);
        },
        openCallback = () => {
          onOpen(reinitialiseHooks);
          setValidationState(buildValidationState(formType));
        };

  setters.added = setAddedDate;
  setters.status = setStatus;
  setters.type = setType;

  if (triggerType === 'add') {
    buttonIcon = 'plus';
    addEditButtonClass = 'button--icon button--add --border-thin';
    singleUser.added = new Date().toLocaleDateString('en-GB');
    showPasswordFields = false;
  } else {
    buttonIcon = 'edit';
    addEditButtonClass = 'button--icon button--edit --border-thin';
    showPasswordFields = true;
  }

  return (
    <Modal
      userid={modalid}
      id={`${idPrefix}-${modalid}`}
      className={`${idPrefix} ${browserClass}`}
      trigger={(
        <Button
          icon={buttonIcon}
          className={addEditButtonClass}
          onClick={openModal}
        />
      )}
      closeIcon={(
        <Button
          icon="close"
          onClick={closeModal}
          className="button--close button--icon"
        />
      )}
      onOpen={() => {
        openCallback();
      }}
      open={modalIsOpen}
    >
      <Modal.Content>
        <Form
          className={`${idPrefix}__form`}
          onSubmit={handleSubmit}
        >
          <Form.Field
            className={`--position-absolute ${idPrefix}__field-status`}
          >
            <StatusSelect
              idPrefix={idPrefix}
              initialStatus={formState.status}
              className={`status--${statusHooked} --min-width`}
              onChange={onChange}
              onBlur={onBlur}
              setStatus={setStatus}
              // error={!validationState.status.fieldIsValid}
            />
            {/* <ValidationStatus
              fieldValidationState={status.email}
            /> */}
          </Form.Field>
          <Form.Field
            className="--half-width --large"
          >
            <Input
              id={`${idPrefix}__name`}
              name="name"
              className="--left-pad-large"
              placeholder="Name"
              defaultValue={formState.name}
              onChange={onChange}
              onBlur={onBlur}
              error={!validationState.name.fieldIsValid}
            />
            <ValidationStatus
              fieldValidationState={validationState.name}
            />
          </Form.Field>
          <Form.Field
            className="--half-width --large"
          >
            <Input
              id={`${idPrefix}__surname`}
              name="surname"
              placeholder="Surname"
              defaultValue={formState.surname}
              onChange={onChange}
              onBlur={onBlur}
              error={!validationState.surname.fieldIsValid}
            />
            <ValidationStatus
              fieldValidationState={validationState.surname}
            />
          </Form.Field>
          <Form.Field
            className="--half-width"
          >
            { /* Eslint incorrectly parses the following rule */ }
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
            <label htmlFor={`${idPrefix}__type`}>Type</label>
            <Select
              id={`${idPrefix}__type`}
              name="type"
              label="Type"
              placeholder="Type"
              options={flattenOptionsObject(selectOptions.userTypeOptions)}
              defaultValue={typeHooked}
              onChange={onChange}
              onBlur={onBlur}
              error={!validationState.type.fieldIsValid}
            />
            <ValidationStatus
              fieldValidationState={validationState.type}
            />
          </Form.Field>
          <Form.Field
            className="--quarter-width --spacer"
          />
          <Form.Field
            className="--quarter-width"
          >
            <DateInput
              id={`${idPrefix}__added`}
              name="added"
              placeholder="dd-mm-yyyy"
              icon={false}
              popupPosition="bottom right"
              animation="horizontal flip"
              duration={300}
              closable
              hideMobileKeyboard
              clearable={dateClearable}
              label="Added"
              value={addedHooked}
              onChange={onChange}
              onBlur={onBlur}
              autoComplete="off"
              error={!validationState.added.fieldIsValid}
            />
            <ValidationStatus
              fieldValidationState={validationState.added}
            />
          </Form.Field>
          <Form.Field
            className="--full-width"
          >
            <Input
              id={`${idPrefix}__email`}
              name="email"
              label="Email address"
              defaultValue={formState.email}
              onChange={onChange}
              onBlur={onBlur}
              error={!validationState.email.fieldIsValid}
            />
            <ValidationStatus
              fieldValidationState={validationState.email}
            />
          </Form.Field>
          {showPasswordFields === true && (
            <div className="--full-width">
              <Form.Field
                className="--full-width"
              >
                <Input
                  id={`${idPrefix}__currentPassword`}
                  name="currentPassword"
                  type="currentPassword"
                  label="Old password"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!validationState.currentPassword.fieldIsValid}
                />
                <ValidationStatus
                  fieldValidationState={validationState.currentPassword}
                />
              </Form.Field>
              <Form.Field
                className="--half-width"
              >
                <Input
                  id={`${idPrefix}__newPassword`}
                  name="newPassword"
                  type="password"
                  label="New password"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!validationState.newPassword.fieldIsValid}
                />
                <ValidationStatus
                  fieldValidationState={validationState.newPassword}
                />
              </Form.Field>
              <Form.Field
                className="--half-width"
              >
                <Input
                  id={`${idPrefix}__repeatNewPassword`}
                  name="repeatNewPassword"
                  type="password"
                  label="Repeat new password"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!validationState.repeatNewPassword.fieldIsValid}
                />
                <ValidationStatus
                  fieldValidationState={validationState.repeatNewPassword}
                />
              </Form.Field>
              <aside className={`${idPrefix}__password-requirements --full-width`}>
                Password must contain at least one (1) character from three (3) of the following categories: Uppercase letter (A-Z) Lowercase letter (a-z) Digit (0-9) Special character
              </aside>
            </div>
          )}
          <Form.Field>
            <Button
              id={`${idPrefix}__save`}
              className={`${idPrefix}__save cta primary`}
              // disabled={validationState.formIsValid}
            >
              Save
            </Button>
          </Form.Field>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

UserModal.propTypes = {
  id: PropTypes.number,
  onOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  singleUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    added: PropTypes.string,
  }),
  triggerType: PropTypes.string,
};

UserModal.defaultProps = {
  id: null,
  triggerType: 'edit',
  singleUser: { ...UserModal.initialState, id: -1 },
};

export default UserModal;
