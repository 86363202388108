import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import {
  Form, Modal, Select, Input, Button,
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

import './styles.scss';
import { locale, validationConfig } from '../../config';
import { buildValidationState, validateAllFields } from '../../services/validation/index';
import ValidationStatus from '../validationStatus/index';

import { browserClass } from '../../browser-detect';


const PermitModal = (props) => {
  let { modalHasInitialised } = props,
      status = 'Inactive',
      startMinDate = '';

  const {
          groupId,
          onSubmit,
          addPermitCallbackAction,
          singlePermit,
          groupsNoLimit,
          modalIsOpen,
          closePermitModal,
          onModalClose,
          showGroupSelect,
          loading,
          currentPage,
        } = props,
        formState = { ...singlePermit },
        formType = 'permits',
        setters = {},
        idPrefix = 'edit-permit',
        dateClearable = false,
        dateFieldClass = (showGroupSelect === true) ? '--quarter-width' : '--half-width',
        today = moment(new Date(), 'DD/MM/YYYY'),
        // Local input states
        [nameHooked, setName] = useState(formState.name),
        [groupIdHooked, setGroupId] = useState(formState.groupId),
        [startHooked, setStartDate] = useState(formState.start),
        [expiresHooked, setExpiresDate] = useState(formState.expires),
        [descriptionHooked, setDescription] = useState(formState.description),
        [vrmHooked, setVrm] = useState(formState.vrm),
        [vrmDescriptionHooked, setVrmDescription] = useState(formState.vrmDescription),
        [validationState, setValidationState] = useState(buildValidationState(formType)),
        groupSelectOptions = groupsNoLimit.map(group => ({
          key: group.id,
          value: group.id,
          text: group.name,
        })),
        setPermitStatus = () => {
          const startCompare = moment(startHooked, 'DD/MM/YYYY'),
                expiresCompare = moment(expiresHooked, 'DD/MM/YYYY').add(1, 'days');

          if (today.isBetween(startCompare, expiresCompare)) {
            status = 'Active';
          } else {
            status = 'Inactive';
          }
        },
        setFormState = (name, value) => {
          formState[name] = value;
        },
        closeModal = useCallback(() => {
          closePermitModal();
          onModalClose(currentPage);
          modalHasInitialised = false;
        }, [closePermitModal, onModalClose]),
        handleSubmit = () => {
          setValidationState({
            ...validateAllFields(formType, formState, validationState),
            submitPending: true,
          });
        },
        updateStateAndHooks = (name, value) => {
          setFormState(name, value);

          if (typeof setters[name] !== 'undefined') {
            setters[name](value);
          }
        },
        onChange = (event, element) => {
          const { name, value } = element;

          updateStateAndHooks(name, value);
        },
        onStartChange = (event, element) => {
          const { name, value } = element;

          if (moment(value, locale.dateFormat) > moment(expiresHooked, locale.dateFormat)) {
            updateStateAndHooks('expires', value);
          }

          updateStateAndHooks(name, value);
          setPermitStatus();
        },
        onBlur = (...params) => {
          let target = null;

          if (params[0].target && params[0].target.value !== undefined) {
            // eslint-disable-next-line prefer-destructuring
            target = params[0].target;
          } else {
            // eslint-disable-next-line prefer-destructuring
            target = params[1];
          }
          const { name, value } = target;
          updateStateAndHooks(name, value);
        };

  if (singlePermit.permitId === -1) {
    startMinDate = validationConfig[formType].start.conditional.conditionalValidation.minDate;
  }

  if (!modalHasInitialised) {
    setFormState('groupId', groupId);
    setFormState('id', singlePermit.permitId);

    setFormState('name', nameHooked);
    setFormState('start', startHooked);
    setFormState('expires', expiresHooked);
    setFormState('description', descriptionHooked);
    setFormState('vrm', vrmHooked);
    setFormState('vrmDescription', vrmDescriptionHooked);

    setters.name = setName;
    setters.start = setStartDate;
    setters.expires = setExpiresDate;
    setters.description = setDescription;
    setters.vrm = setVrm;
    setters.vrmDescription = setVrmDescription;

    if (showGroupSelect === true) {
      setFormState('groupId', groupIdHooked);

      setters.groupId = setGroupId;
    }
  }

  setPermitStatus();

  useEffect(() => {
    const { submitPending, formIsValid } = validationState;

    if (submitPending && formIsValid) {
      onSubmit(formState, currentPage, closeModal, addPermitCallbackAction);
      setValidationState({ ...validationState, submitPending: false });
    }
  }, [
    validationState,
  ]);

  function GroupSelect(elementProps) {
    const { showGroups } = elementProps;

    if (showGroups === true) {
      return (
        <Form.Field
          id={`${idPrefix}__groupId-field`}
          className={`--half-width ${idPrefix}__field-status`}
        >
          { /* Eslint incorrectly parses the following rule */ }
          { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
          <label htmlFor={`${idPrefix}__groupId`}>Group</label>
          <Select
            id={`${idPrefix}__groupId`}
            name="groupId"
            placeholder="Type"
            options={groupSelectOptions}
            value={formState.groupId}
            onChange={onChange}
            onBlur={onBlur}
            search
            error={!validationState.groupId.fieldIsValid}
          />
          <ValidationStatus
            name="groupId"
            formType={formType}
            fieldValidationState={validationState.groupId}
          />
        </Form.Field>
      );
    }

    return null;
  }

  return (
    <Modal
      permitid={singlePermit.permitId}
      id={`${idPrefix}-${singlePermit.permitId}`}
      className={`${idPrefix}  ${browserClass}`}
      open={modalIsOpen}
      closeIcon={(
        <Button
          id={singlePermit.permitId}
          icon="close"
          onClick={closeModal}
          className="button--close button--icon"
        />
      )}
    >
      <Modal.Content>
        <Form
          id={`${idPrefix}__form`}
          onSubmit={handleSubmit}
        >
          <Form.Field
            className="--full-width --large"
          >
            <div
              id={`${idPrefix}__form`}
              className="permit-status-wrapper"
            >
              <span
                className={`status status-${status.toLowerCase()}`}
              />
              This permit is
              {status.toLowerCase()}
            </div>
            <Input
              id={`${idPrefix}__name`}
              name="name"
              className="--left-pad-large"
              placeholder="Permit Holder Name"
              defaultValue={nameHooked}
              onChange={onChange}
              onBlur={onBlur}
              error={!validationState.name.fieldIsValid}
            />
            <ValidationStatus

              name="name"
              formType={formType}
              fieldValidationState={validationState.name}
            />
          </Form.Field>
          <GroupSelect showGroups={showGroupSelect} />
          <Form.Field
            id={`${idPrefix}__start-wrapper`}
            className={dateFieldClass}
            disabled={singlePermit.permitId !== -1}
          >
            <DateInput
              id={`${idPrefix}__start`}
              name="start"
              placeholder="dd-mm-yyyy"
              icon={false}
              popupPosition="bottom right"
              animation="horizontal flip"
              duration={300}
              closable
              hideMobileKeyboard
              clearable={dateClearable}
              label="Start"
              value={startHooked}
              onChange={onStartChange}
              onBlur={onBlur}
              autoComplete="off"
              error={!validationState.start.fieldIsValid}
              minDate={startMinDate}
            />
            <ValidationStatus
              name="start"
              formType={formType}
              fieldValidationState={validationState.start}
            />
          </Form.Field>
          <Form.Field
            className={dateFieldClass}
          >
            <DateInput
              id={`${idPrefix}__expires`}
              name="expires"
              placeholder="dd-mm-yyyy"
              icon={false}
              popupPosition="bottom right"
              animation="horizontal flip"
              duration={300}
              closable
              hideMobileKeyboard
              clearable={dateClearable}
              label="Expires"
              value={expiresHooked}
              onChange={onChange}
              onBlur={onBlur}
              autoComplete="off"
              error={!validationState.expires.fieldIsValid}
              minDate={moment(startHooked, locale.dateFormat).format(locale.dateFormat)}
            />
            <ValidationStatus
              name="expires"
              formType={formType}
              fieldValidationState={validationState.expires}
            />
          </Form.Field>
          <Form.Field
            className="--full-width"
          >
            <Input
              id={`${idPrefix}__description`}
              name="description"
              label="Description of permit"
              defaultValue={descriptionHooked}
              onChange={onChange}
              onBlur={onBlur}
              error={!validationState.description.fieldIsValid}
            />
            <ValidationStatus
              name="description"
              formType={formType}
              fieldValidationState={validationState.description}
            />
          </Form.Field>
          { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
          <label htmlFor={`${idPrefix}__vrm`}>VRM</label>
          <fieldset className="form__vrm-fieldset">
            <Form.Field
              className="--full-width"
            >
              <Input
                id={`${idPrefix}__vrm`}
                name="vrm"
                className="--no-bottom-border"
                defaultValue={vrmHooked}
                onChange={onChange}
                onBlur={onBlur}
                error={!validationState.vrm.fieldIsValid}
                placeholder="Num Plate"
                disabled={formState.id !== -1}
              />
              <ValidationStatus
                name="vrm"
                formType={formType}
                fieldValidationState={validationState.vrm}
              />
            </Form.Field>
            <Form.Field
              className="--full-width"
            >
              <Input
                id={`${idPrefix}__vrmDescription`}
                name="vrmDescription"
                className="--no-bottom-border"
                // label="Vehicle description"
                defaultValue={vrmDescriptionHooked}
                onChange={onChange}
                onBlur={onBlur}
                error={!validationState.vrmDescription.fieldIsValid}
                placeholder="Vehicle description"
                disabled={formState.id !== -1}
              />
              <ValidationStatus
                name="vrmDescription"
                formType={formType}
                fieldValidationState={validationState.vrmDescription}
              />
            </Form.Field>
          </fieldset>
          <Form.Field>
            <Button
              id={`${idPrefix}__save`}
              className={`${idPrefix}__save cta primary`}
              disabled={loading}
            >
              Save
            </Button>
          </Form.Field>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

PermitModal.propTypes = {
  modalHasInitialised: PropTypes.bool,
  groupId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  addPermitCallbackAction: PropTypes.func.isRequired,
  singlePermit: PropTypes.shape({
    permitId: PropTypes.number.isRequired,
    status: PropTypes.string,
    name: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    expires: PropTypes.string.isRequired,
    description: PropTypes.string,
    vrm: PropTypes.string.isRequired,
    vrmDescription: PropTypes.string.isRequired,
  }),
  groupsNoLimit: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })), // .isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closePermitModal: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  showGroupSelect: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
};

PermitModal.defaultProps = {
  modalHasInitialised: false,
  singlePermit: { ...PermitModal.initialState, id: -1 },
  groupsNoLimit: [{ id: -1, name: '' }],
  showGroupSelect: false,
};

export default PermitModal;
