import React from 'react';
import PropTypes from 'prop-types';

// import { Confirm } from 'semantic-ui-react';

import ModalTrigger from '../../../modals/modalTrigger';
import { EditIcon } from '../../../svgIcons/SVGIcons';

import './styles.scss';

const PermitRow = (props) => {
  // let confirmDeleteDialog = false;

  const { rowData, openModal } = props,
        {
          permitId,
          name,
          vrm,
          status,
          start,
          expiry,
        } = rowData;
        // confirmDelete = () => { confirmDeleteDialog = true; },
        // cancelDelete = () => { confirmDeleteDialog = false; },
        // deleteRow = () => {};

  return (
    <tr className="permit-row">
      <td className="larger-font no-bottom-border">
        {name}
      </td>
      <td className="no-bottom-border">
        <div className="vrm">
          {vrm}
        </div>
      </td>
      <td className="no-bottom-border">
        <span className={`status status-${status.toLowerCase()}`} />
        <span className="status-text">{status}</span>
      </td>
      <td className="no-bottom-border">{start}</td>
      <td className="no-bottom-border">{expiry}</td>
      <td className="table-tools table-tools-permit no-bottom-border">
        <ModalTrigger
          modalid={permitId}
          icon={EditIcon}
          className="button--edit"
          onClick={openModal}
        >
          <EditIcon />
        </ModalTrigger>
        {/* <Confirm
          open={confirmDeleteDialog}
          onCancel={cancelDelete}
          onConfirm={deleteRow}
        /> */}
      </td>
    </tr>
  );
};

PermitRow.propTypes = {
  rowData: PropTypes.shape({
    groupId: PropTypes.number,
    name: PropTypes.string.isRequired,
    vrm: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    expiry: PropTypes.string.isRequired,
  }).isRequired,
  openModal: PropTypes.func.isRequired,
};

export default PermitRow;
