import moment from 'moment';

import { locale, validationConfig } from '../../config';

const excludeFromValidators = ['formIsValid', 'submitPending'],
      validators = {
        required: (value) => {
          if (!value || value.length < 1) {
            return false;
          }

          return true;
        },
        format: (value, regex) => {
          if (typeof value !== 'undefined' && value.length > 0 && !regex.test(value)) {
            return false;
          }
          return true;
        },
        maxLength: (value, maxLength) => {
          if (value && value.length > maxLength) {
            return false;
          }
          return true;
        },
        min: (value, minValue) => {
          if (value < minValue) {
            return false;
          }
          return true;
        },
        minDate: (value, minValue) => {
          const today = moment(value, locale.dateFormat),
                minMoment = moment(minValue, locale.dateFormat);

          if (today.isBefore(minMoment)) {
            return false;
          }
          return true;
        },
        minDateField: (value, minValueField, formState) => {
          if (moment(value, locale.dateFormat).isBefore(moment(formState[minValueField], locale.dateFormat))) {
            return false;
          }
          return true;
        },
      },
      resetValidationState = (formType, validationState) => {
        const newState = { ...validationState };

        Object.keys(validationConfig[formType]).forEach((field) => {
          if (field !== 'formIsValid') {
            Object.keys(validationConfig[formType][field]).forEach((validator) => {
              newState[field][validator] = true;
            });
          }
          newState[field].fieldIsValid = true;
        });

        return newState;
      },
      validateField = (
        validator, fieldState, validatorParams, formState,
      ) => validator(fieldState, validatorParams, formState),
      validateFieldState = (formType, field, validationFieldState) => {
        let valid = true;

        Object.keys(validationConfig[formType][field]).forEach((validator) => {
          if (validator === 'conditional') {
            Object.keys(
              validationConfig[formType][field].conditional.conditionalValidation,
            ).forEach((conditionalValidator) => {
              if (validationFieldState[conditionalValidator] === false) {
                valid = false;
              }
            });
          } else if (validationFieldState[validator] === false) {
            valid = false;
          }
        });
        return valid;
      },
      validateFormState = (formType, validationState) => {
        let valid = true;

        Object.keys(validationState).forEach((field) => {
          if (!excludeFromValidators.includes(field)) {
            Object.keys(validationConfig[formType][field]).forEach((validator) => {
              if (validationState[field][validator] === false) {
                valid = false;
              }
            });
          }
        });

        return valid;
      };


export const buildValidationState = (formType) => {
  const state = {};
  state.formIsValid = true;
  Object.keys(validationConfig[formType]).forEach((field) => {
    state[field] = {};
    state[field].fieldIsValid = true;
    Object.keys(validationConfig[formType][field]).forEach((validator) => {
      state[field][validator] = true;
    });
  });

  state.submitPending = false;

  return state;
};

export const validateAllFields = (formType, formState, validationState) => {
  const formConfig = validationConfig[formType],
        newState = resetValidationState(formType, validationState);

  Object.keys(formConfig).forEach((field) => {
    if (field !== 'formIsValid') {
      Object.keys(formConfig[field]).forEach((validator) => {
        if (validator === 'conditional') {
          const {
            conditionalField,
            conditionalOperator,
            comparisonValue,
            conditionalValidation,
          } = formConfig[field][validator];

          // eslint-disable-next-line default-case
          switch (conditionalOperator) {
            case '===': {
              if (formState[conditionalField] === comparisonValue) {
                Object.keys(conditionalValidation).forEach((conditionalValidator) => {
                  const tmpState = validateField(
                    validators[conditionalValidator],
                    formState[field],
                    formConfig[field].conditional.conditionalValidation[conditionalValidator],
                    formState,
                  );

                  newState[field][conditionalValidator] = tmpState;
                });
              }
            }
          }
        } else if (typeof validators[validator] !== 'undefined') {
          newState[field][validator] = validateField(
            validators[validator],
            formState[field],
            formConfig[field][validator],
            formState,
          );
        }
      });

      newState[field].fieldIsValid = validateFieldState(formType, field, newState[field]);
    }
  });
  newState.formIsValid = validateFormState(formType, newState);

  return newState;
};
