import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'semantic-ui-react';
import Navigation from '../../components/navigation/Navigation';
import SearchBar from '../../components/searchBar/SearchBar';

import { browserClass } from '../../browser-detect';

import './styles.scss';

const PageLayout = ({ children }) => (
  <div className={`${browserClass} PageLayout`}>
    <Navigation />
    <div style={{ flex: '1 1 auto' }}>
      <SearchBar />
      {process.env.REACT_APP_ENVIRONMENT === 'DEV'
      && (
        <Label className="environment" color="green">
          Dev
        </Label>
      )
      }
      <div className="PageContent">{children}</div>
    </div>
  </div>
);

PageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PageLayout;
