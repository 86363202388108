import React from 'react';
import PropTypes from 'prop-types';

import PageLayout from '../../containers/pageLayout/PageLayout';
import PermitTable from '../../containers/permitTable/PermitTable';

const Permits = (props) => {
  const { match } = props;

  let { groupId } = match.params;

  groupId = parseInt(groupId, 10);

  return (
    <div className="App">
      <PageLayout>
        <PermitTable
          groupId={groupId}
        />
      </PageLayout>
    </div>
  );
};

Permits.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      groupId: PropTypes.string.isRequired,
    }),
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Permits;
