import React from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fetchAuthentication } from './actions';
import PrivateRoute from './components/privateRoute/privateRoute';
import PublicOnlyRoute from './components/publicOnlyRoute/PublicOnlyRoute';
import Authentication from './containers/authentication/Authentication';
import Groups from './pages/Groups';
import Users from './pages/Users';
import Permits from './pages/Permits';
import Search from './pages/Search';
import NotFound from './pages/NotFound';


import './css/semantic.css';
import './scss/App.scss';

const App = () => {
        const authProps = {
          action: fetchAuthentication,
        };

        return (
          <Switch>
            <PublicOnlyRoute
              exact
              path="/login"
              routeComponent={routeProps => (
                <Authentication {...routeProps} {...authProps} />
              )}
            />
            <PrivateRoute exact path="/">
              <Redirect to="/groups" />
            </PrivateRoute>
            <PrivateRoute exact path="/groups" routeComponent={Groups} />
            <PrivateRoute exact path="/groups/:groupId" routeComponent={Permits} />
            <PrivateRoute exact path="/users" routeComponent={Users} />
            <PrivateRoute exact path="/search" routeComponent={Search} />
            <PrivateRoute routeComponent={NotFound} />
          </Switch>
        );
      },
      mapStateToProps = (state, ownProps) => ({
        loggedIn: state.loggedIn,
        ...ownProps,
      });

App.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(App);
